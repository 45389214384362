const getDefaultState = () => {
  return {
    showBanner: false
  }
}

const state = getDefaultState()

const getters = {
  showBannerState: state => {
    return state.showBanner
  },
  displayProctorBanner (state, rootGetters) {
    return state.showBanner && rootGetters.isViewingAsProctor
  }
}

const actions = {
  setShowBanner ({ commit }, value) {
    commit('SHOW_BANNER', value)
  },
  clearBannerState: ({ commit }) => {
    commit('CLEAR_BANNER_STATE')
  }
}

const mutations = {
  SHOW_BANNER (state, value) {
    state.showBanner = value
  },
  CLEAR_BANNER_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
