import { DEFAULT_OPTIONS } from '@/consts/filter-default-option'
import { videoFilterEnums } from '@/residency/views/video/video-enums'

const DEFAULT_FILTER_MENU = Object.freeze({
  [videoFilterEnums.ALL]: {
    surgicalApproach: DEFAULT_OPTIONS.ALL,
    role: DEFAULT_OPTIONS.ALL
  },
  [videoFilterEnums.SIMULATION]: {
    role: DEFAULT_OPTIONS.ALL
  },
  [videoFilterEnums.SURGICAL]: {
    surgicalApproach: DEFAULT_OPTIONS.ALL,
    role: DEFAULT_OPTIONS.ALL
  },
  [videoFilterEnums.OTHER]: {
    role: DEFAULT_OPTIONS.ALL
  }
})

const defaultState = () => {
  return {
    textSearchFilter: '',
    filterMenu: DEFAULT_FILTER_MENU
  }
}

const state = defaultState()

export const getters = {
  textSearchFilter: state => state.textSearchFilter,
  filterMenu: state => state.filterMenu
}

export const actions = {
  setTextSearchFilter: ({ commit }, filterValue) => {
    commit('SET_TEXT_SEARCH_FILTER', filterValue)
  },
  updateFilterMenu: ({ commit }, { videoType, filterOptions }) => {
    commit('UPDATE_FILTER_MENU', { videoType, filterOptions })
  },
  resetFilterMenu: ({ commit }) => {
    commit('RESET_FILTER_MENU')
  }
}

export const mutations = {
  SET_TEXT_SEARCH_FILTER (state, filterValue) {
    state.textSearchFilter = filterValue
  },
  UPDATE_FILTER_MENU (state, { videoType, filterOptions }) {
    state.filterMenu[videoType][filterOptions.filterType] = filterOptions.filterValue
  },
  RESET_FILTER_MENU (state) {
    Object.assign(state, JSON.parse(JSON.stringify(defaultState())))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
