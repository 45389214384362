import { DEFAULT_OPTIONS } from '@/consts/filter-default-option'

const DEFAULT_FILTER_MENU = Object.freeze({
  program: DEFAULT_OPTIONS.ALL,
  simseiMembers: DEFAULT_OPTIONS.ALL,
  simseiEventAttendees: DEFAULT_OPTIONS.ALL,
  specialty: DEFAULT_OPTIONS.ALL,
  activities: DEFAULT_OPTIONS.ALL
})

const defaultState = () => {
  return {
    textSearchFilter: '',
    filterMenu: DEFAULT_FILTER_MENU
  }
}

const state = defaultState()

export const getters = {
  textSearchFilter (state) {
    return state.textSearchFilter
  },
  filterMenu (state) {
    return state.filterMenu
  }
}

export const actions = {
  setTextSearchFilter: ({ commit }, filterValue) => {
    commit('SET_TEXT_SEARCH_FILTER', filterValue)
  },
  updateFilterMenu: ({ commit }, { filterType, filterValue }) => {
    commit('UPDATE_FILTER_MENU', { filterType, filterValue })
  },
  resetFilterMenu: ({ commit }) => {
    commit('RESET_FILTER_MENU')
  }
}

export const mutations = {
  SET_TEXT_SEARCH_FILTER (state, filterValue) {
    state.textSearchFilter = filterValue
  },
  UPDATE_FILTER_MENU (state, { filterType, filterValue }) {
    state.filterMenu[filterType] = filterValue
  },
  RESET_FILTER_MENU (state) {
    Object.assign(state, structuredClone(defaultState()))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
