import { simseiApi } from '@/residency/app-props'
import store from '@/residency/store'

/**
 * Post a log message to the webservice so that it can log the error on the back-end
 * in the webservice logs to allow us to know of any issues happening on the front-end.
 *
 * @param {(Error|string)} message The exception object (if passing an Error) or message string
 *                                 (if anything else) to send to the webservice for logging
 * @returns Promise representing the webservice response
 */
export function postLog (message) {
  if (store.getters.isAuthenticated) {
    return simseiApi.post('/vue-log', message)
  }
}
