import { simseiApi } from '@/residency/app-props'

const DEFAULT_HIGHEST_SCORE = {
  accuracy: 0,
  rotation: 0,
  distance: 0,
  total: 0,
  collisions: 0,
  time: 0
}

const getDefaultState = () => {
  return {
    highestScore: DEFAULT_HIGHEST_SCORE
  }
}

const state = getDefaultState()

const getters = {
  highestScore (state) {
    return state.highestScore
  }
}

const actions = {
  getHighestScore: async ({ commit, rootGetters }) => {
    const userType = rootGetters.isInstructor ? 'faculty' : 'resident'
    const { data } = await simseiApi.get(`ar-attempt/${userType}/high-score?subActivityId=${rootGetters.arSelectedSubActivityId}&level=${rootGetters.arSelectedLevel}`)
    if (data.length) {
      const highestScore = {}
      data.forEach(metric => {
        highestScore[metric.arMetric.name] = metric.score
      })
      commit('SET_HIGHEST_SCORE', highestScore)
    } else {
      commit('SET_HIGHEST_SCORE', DEFAULT_HIGHEST_SCORE)
    }
  }
}

const mutations = {
  SET_HIGHEST_SCORE (state, score) {
    state.highestScore = score
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
