const getDefaultState = () => {
  return {
    selectedExistingSystem: '',
    selectedExistingSystemId: null,
    selectedSystemOption: '',
    institutionNameInput: '',
    selectedInstitution: {}
  }
}
const state = getDefaultState()

const getters = {
  selectedExistingSystem: state => {
    return state.selectedExistingSystem
  },
  selectedExistingSystemId: state => {
    return state.selectedExistingSystemId
  },
  institutionNameInput: state => {
    return state.institutionNameInput
  },
  selectedSystemOption: state => {
    return state.selectedSystemOption
  },
  selectedInstitution: state => {
    return state.selectedInstitution
  }
}

const actions = {
  setSelectedExistingSystem: ({ commit }, system) => {
    commit('SET_SELECTED_EXISTING_SYSTEM', system)
  },
  setSelectedExistingSystemId: ({ commit }, system) => {
    commit('SET_SELECTED_EXISTING_SYSTEM_ID', system)
  },
  setInstitutionNameInput: ({ commit }, institutionName) => {
    commit('SET_INSTITUTION_NAME_INPUT', institutionName)
  },
  setSelectedSystemOption: ({ commit }, option) => {
    commit('SET_SELECTED_SYSTEM_OPTION', option)
  },
  setSelectedInstitution: ({ commit }, institution) => {
    commit('SET_SELECTED_INSTITUTION', institution)
  }
}

const mutations = {
  SET_SELECTED_EXISTING_SYSTEM (state, payload) {
    state.selectedExistingSystem = payload
  },
  SET_SELECTED_EXISTING_SYSTEM_ID (state, payload) {
    state.selectedExistingSystemId = payload
  },
  SET_INSTITUTION_NAME_INPUT (state, payload) {
    state.institutionNameInput = payload
  },
  SET_SELECTED_SYSTEM_OPTION (state, payload) {
    state.selectedSystemOption = payload
  },
  SET_SELECTED_INSTITUTION (state, payload) {
    state.selectedInstitution = payload
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
