import { simseiApi } from '@/residency/app-props'
import noCache from '@/residency/consts/nocache-headers.js'

const getDefaultState = () => {
  return {
    dashboardSortedCourseProgress: []
  }
}

const state = getDefaultState()

const getters = {
  getDashboardSortedCourseProgress (state) {
    return state.dashboardSortedCourseProgress
  }
}

const actions = {
  getDashboardCourseProgressAndSort: async ({ commit, rootGetters }, userGroupId) => {
    const response = await simseiApi.get('/dashboard-progress/aggregate-progress/', {
      params: {
        userGroupId: userGroupId
      },
      headers: {
        ...noCache
      }
    })

    const coursesObj = response.data
    const coursesArray = []

    // Convert the courses Obj into an array and sort it by courseOrder
    Object.values(coursesObj).forEach(course => {
      if (!course.isPrepCourse) {
        coursesArray.push(course)
        course.activities = Object.values(course.activities).sort((a, b) => a.activityOrder - b.activityOrder)
      }
    })

    coursesArray.sort((a, b) => a.courseOrder - b.courseOrder)
    commit('SET_DASHBOARD_SORTED_COURSE_PROGRESS', coursesArray)
  },
  clearDashboardProgressData: ({ commit, rootGetters }) => {
    commit('CLEAR_DASHBOARD_PROGRESS_STATE')
  },
  fetchCourseAverages: async ({ commit }, { courseId, groupId }) => {
    const res = await simseiApi.get('/dashboard-progress/average-course-progress', {
      params: {
        courseId: courseId,
        gid: groupId
      }
    })

    const courseAverages = res.data
    courseAverages.avgGroupProgressPercentage = Math.trunc(courseAverages.avgGroupProgressPercentage)
    return courseAverages
  },
  fetchResidentCourseProgress: async ({ commit }, { userId, courseId, groupId }) => {
    const response = await simseiApi.get('/dashboard-progress/user-course-progress', {
      params: {
        userId: userId,
        courseId: courseId,
        userGroupId: groupId
      },
      headers: {
        ...noCache
      }
    })

    const course = response.data
    // convert course.activities from map to array and sort by activity order
    const activityMap = course.activities
    if (activityMap) {
      const activityArray = Object.keys(activityMap).map(activityName => {
        const activity = activityMap[activityName]
        activity.activityName = activityName
        return activity
      })
      activityArray.sort((a1, a2) => a1.activityOrder - a2.activityOrder)

      course.activities = activityArray
    }
    return course
  }
}

const mutations = {
  SET_DASHBOARD_SORTED_COURSE_PROGRESS (state, progress) {
    state.dashboardSortedCourseProgress = progress
  },
  CLEAR_DASHBOARD_PROGRESS_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
