import { videoTypeEnums } from '@/residency/views/video/video-enums'
import generalEndpoints from '@/residency/router/endpoints/general-endpoints'

const getDefaultState = () => {
  return {
    attemptedUploadResume: null // Object (info) or null
  }
}

const state = getDefaultState()

const getters = {
  attemptedUploadResumeInfo (state) {
    return state.attemptedUploadResume
  }
}

const actions = {
  prepareResumeAttempt ({ commit, rootGetters, dispatch }, uploadId) {
    commit('SET_ATTEMPTED_UPLOAD_RESUME', null) // reset previous attempt (if any)

    if (!rootGetters.pausedUploadTasksMap.has(uploadId)) {
      throw new Error('Cannot resume upload because it does not exist')
    }

    const uploadTask = rootGetters.pausedUploadTasksMap.get(uploadId)
    commit('SET_ATTEMPTED_UPLOAD_RESUME', uploadTask)

    let url = ''
    switch (uploadTask.videoType.toLowerCase()) {
      case videoTypeEnums.SIMULATION: {
        const { activityId, activityCourseId } = uploadTask
        url = `/${generalEndpoints.VIDEO_UPLOAD}/${videoTypeEnums.SIMULATION}/${activityCourseId}/${activityId}`
      } break
      case videoTypeEnums.SURGICAL:
        url = `/${generalEndpoints.VIDEO_UPLOAD}/${videoTypeEnums.SURGICAL}`
        dispatch('setSurgicalApproachProcedure', {
          approach: uploadTask.surgicalApproach,
          procedure: uploadTask.surgicalProcedure
        })
        break
      case videoTypeEnums.OTHER:
        url = `/${generalEndpoints.VIDEO_UPLOAD}/${videoTypeEnums.OTHER}`
        dispatch('setOtherVideoTitle', uploadTask.otherAssessmentTitle)
        break
      default:
        throw new Error(`Unknown video type: ${uploadTask}`)
    }

    return `${url}?resumingUpload=true`
  }
}

const mutations = {
  SET_ATTEMPTED_UPLOAD_RESUME (state, attemptedUploadResume) {
    state.attemptedUploadResume = attemptedUploadResume
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
