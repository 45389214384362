import { simseiApi } from '@/residency/app-props'

const defaultState = () => {
  return {
    userGroupUsers: [],
    programFaculty: [],
    programProctors: [],
    unregisteredUsers: [],
    roleIds: {},
    defaultCurriculums: []
  }
}
const state = defaultState()

const getters = {
  userGroupUsers (state) {
    return state.userGroupUsers
  },
  getProgramIdForGroups (state) {
    return state.userGroupUsers[0].programId || -1
  },
  adminUnexpiredGroups (state) {
    return state.userGroupUsers.filter(group => !group.isExpired && !group.isUnassignedGroup)
  },
  adminExpiredUserGroups (state) {
    return state.userGroupUsers.filter(group => group.isExpired && !group.isUnassignedGroup)
  },
  adminUnassignedGroup (state) {
    return state.userGroupUsers.find(group => group.isUnassignedGroup)
  },
  adminProgramFaculty (state) {
    return state.programFaculty
  },
  adminProgramProctors (state) {
    return state.programProctors
  },
  adminUnregisteredUsers (state) {
    return state.unregisteredUsers
  },
  adminRoleIds (state) {
    return state.roleIds
  },
  areUsersFetched (state) {
    return state.userGroupUsers.length || state.programFaculty.length || state.programProctors.length
  },
  defaultCurriculums (state) {
    return state.defaultCurriculums
  }
}

const actions = {
  fetchUserGroupUsers: async ({ commit }, programId) => {
    const resp = await simseiApi.get(`/user-group/program/${programId}`)
    commit('SET_USER_GROUP_USERS', resp.data)
  },
  fetchProgramFaculty: async ({ commit }, programId) => {
    const resp = await simseiApi.get('/user/search/findFacultyByProgramId', {
      params: {
        id: programId,
        projection: 'inlineManageFacultyUserDetails'
      }
    })
    commit('SET_PROGRAM_FACULTY', resp.data._embedded.user)
  },
  fetchProgramProctors: async ({ commit }, programId) => {
    const resp = await simseiApi.get(`/program/proctors/${programId}`)
    commit('SET_PROGRAM_PROCTORS', resp.data)
  },
  fetchUnregisteredUsers: async ({ commit }, programId) => {
    const resp = await simseiApi.get(`/user/unregistered-users/${programId}`)
    commit('SET_UNREGISTERED_USERS', resp.data)
  },
  fetchRoleIds: async ({ commit }) => {
    const roleIdArr = (await simseiApi.get('/role')).data._embedded.role
    const roleIdMap = {}
    roleIdArr.forEach(role => {
      roleIdMap[role.role] = role.id
    })
    commit('SET_ROLE_IDS', roleIdMap)
  },
  updateResidentCurrentUserGroup: async ({ commit, state }, { user, newGroupId, oldGroupId }) => {
    await simseiApi.patch('/user-group/change-user-group', user, {
      params: { oldGroupId, newGroupId }
    })
  },
  reactivateUser: async ({ dispatch }, { userId }) => {
    await simseiApi.patch('/user/reactivate-user', { id: userId })
  },
  inactivateUser: async ({ commit, state }, { userId }) => {
    await simseiApi.patch('/user/inactivate-user', { id: userId })
  },
  createNewGroup: async ({ dispatch }, groupInfo) => {
    await simseiApi.post('/user-group/create-group', groupInfo)
    await dispatch('fetchUserGroupUsers', groupInfo.parentProgramId)
  },
  editGroup: async ({ commit }, { gid, payload }) => {
    await simseiApi.patch(`/user-group/${gid}/edit-group`, payload)
  },
  updateProctorGroups: async ({ commit }, { userId, proctorGroupIds }) => {
    const user = {
      id: userId,
      proctorGroups: proctorGroupIds.map(groupId => ({ id: groupId }))
    }
    await simseiApi.patch('/user/update-proctor-groups', user)
  },
  batchAddUnregisteredUsers: async ({ commit }, payload) => {
    return simseiApi.post(`/user/batch/${payload.programId}?sendEmail=${payload.sendEmail}`, payload.users)
  },
  batchUpdateCurrentUserGroup: async ({ commit }, users) => {
    await simseiApi.patch('user-group/batch/change-user-group', users)
  },
  batchInactivateUsers: async ({ commit }, users) => {
    await simseiApi.patch('/user/batch/inactivate-users', users)
  },
  clearUnregisteredUsers: ({ commit }) => {
    commit('SET_UNREGISTERED_USERS', [])
  },
  deleteUser: async ({ commit }, id) => {
    await simseiApi.delete(`/user/${id}`)
  },
  fetchDefaultCurriculums: async ({ commit }) => {
    const resp = await simseiApi.get('/user-group/default-curriculum')
    commit('SET_DEFAULT_CURRICULUMS', resp.data)
  }
}

const mutations = {
  SET_USER_GROUP_USERS (state, payload) {
    state.userGroupUsers = payload
  },
  SET_PROGRAM_FACULTY (state, payload) {
    state.programFaculty = payload
  },
  SET_UNREGISTERED_USERS (state, payload) {
    state.unregisteredUsers = payload
  },
  SET_PROGRAM_PROCTORS (state, payload) {
    state.programProctors = payload
  },
  SET_ROLE_IDS (state, payload) {
    state.roleIds = payload
  },
  CLEAR_MANAGE_USER_STATE (state) {
    Object.assign(state, defaultState())
  },
  SET_DEFAULT_CURRICULUMS (state, payload) {
    state.defaultCurriculums = payload
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
