import { simseiApi, dispatchCheckAccessToken } from '@/residency/app-props'
import store from '@/residency/store'
import log from '@/residency/utils/log'
import { videoTypeEnums } from '@/residency/views/video/video-enums'

const tus = require('tus-js-client')

const getDefaultState = () => {
  return {
    peerVideos: [],
    instructorVideos: [],
    adminVideos: [],
    assessedVideos: [],
    mySimulationVideos: [],
    mySurgicalVideos: [],
    myOtherVideos: []
  }
}

const state = getDefaultState()

function getProgressEstimate (resBody, uploadId, rootGetters) {
  if (!resBody) {
    throw new Error('No response body')
  }

  let secondsRemaining = -1
  try {
    secondsRemaining = Number(JSON.parse(resBody).secondsRemaining)
  } catch (e) {
    return -1
  }

  if (uploadId && rootGetters.videoUploadProgressInfo.has(uploadId)) {
    return secondsRemaining
  } else {
    return -1
  }
}

function buildTusMetadata (videoAsmt, fileName, isSharingVideo, sharedVideoDescription, streamInfo) {
  // serialize video asmt metadata and encode it as base64 (as per Tus spec)
  const videoAsmtBase64 = Buffer.from(JSON.stringify(videoAsmt)).toString('base64')
  const filenameBase64 = Buffer.from(fileName).toString('base64')
  const isSharingVideoBase64 = Buffer.from(isSharingVideo.toString()).toString('base64')
  const streamInfoBase64 = Buffer.from(JSON.stringify(streamInfo)).toString('base64')

  // build tus upload metadata
  const filenameMetadata = `filename ${filenameBase64}`
  const isSharingVideoMetadata = `isSharingVideo ${isSharingVideoBase64}`
  const videoAsmtMetadata = `serializedVideoAsmtMetadata ${videoAsmtBase64}`
  const streamInfoMetadata = `streamInfo ${streamInfoBase64}`

  // add required tus metadata fields to the metadata string
  let csv = `${filenameMetadata},${videoAsmtMetadata},${isSharingVideoMetadata},${streamInfoMetadata}`

  // add optional tus metadata fields to the metadata string
  if (sharedVideoDescription) {
    // sharedVideoDescription is optional since a user can have an empty string as their sharedVideoDescription
    const sharedVideoDescriptionBase64 = Buffer.from(sharedVideoDescription).toString('base64')
    const sharedVideoDescriptionMetadata = `sharedVideoDescription ${sharedVideoDescriptionBase64}`

    csv += `,${sharedVideoDescriptionMetadata}`
  }

  return csv
}

const getters = {
  peerVideos (state) {
    return state.peerVideos
  },
  instructorVideos (state) {
    return state.instructorVideos
  },
  adminVideos (state) {
    return state.adminVideos
  },
  assessedVideos (state) {
    return state.assessedVideos
  },
  mySimulationVideos (state) {
    return state.mySimulationVideos
  },
  mySurgicalVideos (state) {
    return state.mySurgicalVideos
  },
  myOtherVideos (state) {
    return state.myOtherVideos
  }
}

const actions = {
  getSelfCreatedVideoAsmts: async ({ dispatch }, videoType) => {
    const resp = await simseiApi.get(`/video/${videoType}/self`)
    dispatch('updateSavedVideosTableState', {
      videoType,
      videos: resp.data
    })
  },
  getPeerVideoAsmts: async ({ commit }, videoType) => {
    commit('RESET_PEER_VIDEO_ASMT_STATE')
    const resp = await simseiApi.get(`video/${videoType}/unassessed/peer`)
    commit('SET_PEER_VIDEOS', resp.data)
  },
  getInstructorVideoAsmsts: async ({ commit }, videoType) => {
    const resp = await simseiApi.get(`/video/${videoType}/unassessed/instructor`)
    commit('SET_INSTRUCTOR_VIDEOS', resp.data)
  },
  getInstructorVideoAsmstsForGroup: async ({ commit }, { videoType, groupId }) => {
    const resp = await simseiApi.get(`/video/${videoType}/unassessed/instructor/${groupId}`)
    commit('SET_INSTRUCTOR_VIDEOS', resp.data)
  },
  getAdminVideoAsmts: async ({ commit }, programId) => {
    const resp = await simseiApi.get('/video/admin', { params: { pid: programId } })
    commit('SET_ADMIN_VIDEOS', resp.data)
    return resp.data
  },
  getVideoAsmtWithPlayerDetails: async ({ commit }, payload) => {
    const response = await simseiApi.get(`/video/${payload.videoType}/full-details/${payload.videoAsmtId}`)
    return response.data
  },
  /**
   * Manual video upload called from the video library page
   */
  uploadVideoAsmt: async ({ dispatch, commit, rootGetters }, {
    videoAsmt,
    attachments,
    isSharingVideo,
    sharedVideoDescription,
    onUploadIdAllocated,
    usersToNotify
  }) => {
    // use Tus creation extension to create a new upload URLs
    // https://tus.io/protocols/resumable-upload.html#creation
    if (attachments.length > 1) {
      throw new Error('Not implemented - expected only one attachment')
    }

    const file = attachments[0] // only one attachment is supported (laparoscopic stream)
    const streamInfo = {
      camera: file.camera,
      hasThumbnails: file.hasThumbnails
    }

    const csv = buildTusMetadata(videoAsmt, file.name, isSharingVideo, sharedVideoDescription, streamInfo)
    const res = await simseiApi.post('tus/upload', '', {
      headers: {
        'Tus-Resumable': '1.0.0',
        'Content-Type': 'application/offset+octet-stream',
        'Upload-Length': file.size,
        'Upload-Metadata': csv
      }
    })

    const tusLocation = res.headers.location
    const uploadId = tusLocation.split('/').pop()
    const creationResponse = uploadWithTus(
      file,
      tusLocation,
      rootGetters,
      commit,
      (upload) => {
        commit('INITIALIZE_UPLOAD_PROGRESS', { uploadId, uploadTask: upload })
      }
    )
    onUploadIdAllocated(uploadId)

    const uploadInfo = (await creationResponse).data
    await dispatch('finishVideoAsmtUpload', {
      videoAsmt,
      uploadInfo,
      streamInfo,
      isSharingVideo,
      sharedVideoDescription,
      usersToNotify
    })
  },
  finishVideoAsmtUpload: async ({ dispatch }, {
    videoAsmt,
    uploadInfo,
    streamInfo,
    isSharingVideo,
    sharedVideoDescription,
    usersToNotify
  }) => {
    const info = {
      streamType: streamInfo.camera,
      hasThumbnails: streamInfo.hasThumbnails,
      id: uploadInfo.uploadId
    }
    const userType = store.getters.isInstructor ? 'faculty' : 'resident'

    await simseiApi.post(`video/${videoAsmt.videoType}/${userType}/upload/finish`, {
      videoAsmt,
      uploadInfo: info,
      isSharingVideo,
      sharedVideoDescription,
      usersToNotify,
      tusUploadId: uploadInfo.uploadId
    }, {
      headers: { 'Content-Type': 'application/json' }
    })

    setTimeout(() => {
      // refresh the list of processing submissions after a delay
      dispatch('fetchProcessingSubmissions')
    }, 1000)
  },
  submitVideoAsmt: async ({ commit }, payload) => {
    const resp = await simseiApi.patch(`video/${payload.videoType}/resident/submit/video-asmt`, payload.videoAsmt)
    return resp.data
  },
  submitFacultyVideo: async ({ commit }, payload) => {
    const resp = await simseiApi.patch(`video/${payload.videoAsmt.videoType}/faculty/submit/video-asmt`, payload)
    return resp.data
  },
  deleteVideoAsmt: ({ commit }, videoAsmtId) => {
    return simseiApi.delete(`/video/${videoAsmtId}`)
  },
  setVideoLibraryFilter: ({ commit }, filterValue) => {
    commit('SET_VIDEO_LIBRARY_FILTER', filterValue)
  },
  resetVideoAsmtState: ({ commit }) => {
    commit('RESET_VIDEO_ASMT_STATE')
  },
  fetchAssessedVideosForUser: async ({ commit }, userGroupId) => {
    const res = await simseiApi.get(`/video/assessed-videos/${userGroupId}`)
    commit('SET_ASSESSED_VIDEOS', res.data)
  },
  acknowledgeNewAssessedVideo: ({ commit }, asmtId) => {
    return simseiApi.post(`/video/acknowledge-assessed-video/${asmtId}`)
  },
  updateVideoAsmtActivity: ({ commit, state }, { videoId, newActivityId }) => {
    return simseiApi.patch(`/video/simulation/update-activity?videoId=${videoId}&newActivityId=${newActivityId}`)
  },
  updateSurgicalApproachProcedure: ({ commit, state }, payload) => {
    return simseiApi.patch('/video/surgical/update-approach-procedure', payload)
  },
  updateOtherTitle: ({ commit, state }, payload) => {
    return simseiApi.patch('/video/other/update-title', payload)
  },
  savedVideoTableFetchProcessingSubmissions: ({ dispatch }, {
    newProcessingSubmissions, oldProcessingSubmissions, videoType
  }) => {
    if (newProcessingSubmissions.length < oldProcessingSubmissions.length) {
      async function fetchSubmissions () {
        await dispatch('getSelfCreatedVideoAsmts', videoType)
      }

      fetchSubmissions()
      setTimeout(() => {
        // try again after a delay in case it takes a bit for submission
        // to move from processing to saved
        fetchSubmissions()
      }, 2500)
    }
  },
  clearSavedVideosTableState: ({ dispatch }, videoType) => {
    dispatch('updateSavedVideosTableState', {
      videoType,
      videos: []
    })
  },
  updateSavedVideosTableState: ({ commit }, { videoType, videos }) => {
    switch (videoType) {
      case videoTypeEnums.SIMULATION:
        commit('SET_MY_SIMULATION_VIDEOS', videos)
        break
      case videoTypeEnums.SURGICAL:
        commit('SET_MY_SURGICAL_VIDEOS', videos)
        break
      case videoTypeEnums.OTHER:
        commit('SET_MY_OTHER_VIDEOS', videos)
        break
      default:
        this.$log.error('Unknown video type found', videoType)
    }
  },
  uploadWithTus: ({ commit, rootGetters }, { file, uploadUrl, onUploadTaskCreated }) => {
    return uploadWithTus(file, uploadUrl, rootGetters, commit, onUploadTaskCreated)
  }
}

const mutations = {
  ADD_PEER_VIDEO (state, video) {
    state.peerVideos.push(video)
  },
  ADD_INSTRUCTOR_VIDEO (state, video) {
    state.instructorVideos.push(video)
  },
  SET_PEER_VIDEOS (state, videos) {
    state.peerVideos = videos
  },
  SET_INSTRUCTOR_VIDEOS (state, videos) {
    state.instructorVideos = videos
  },
  SET_ADMIN_VIDEOS (state, videos) {
    state.adminVideos = videos
  },
  SET_VIDEO_LIBRARY_FILTER (state, filterValue) {
    state.videoLibraryFilter = filterValue
  },
  RESET_VIDEO_ASMT_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  SET_ASSESSED_VIDEOS (state, videos) {
    state.assessedVideos = videos
  },
  SET_MY_SIMULATION_VIDEOS (state, videos) {
    state.mySimulationVideos = videos
  },
  SET_MY_SURGICAL_VIDEOS (state, videos) {
    state.mySurgicalVideos = videos
  },
  SET_MY_OTHER_VIDEOS (state, videos) {
    state.myOtherVideos = videos
  },
  RESET_PEER_VIDEO_ASMT_STATE (state) {
    state.peerVideos = []
  }
}

/**
 * Start an upload for a large File (>2GB or >1GB on bad networks).
 * Resolves with a Promise that contains Tus upload information when an upload
 * succeeds.
 */
async function uploadWithTus (file, uploadUrl, rootGetters, commit, onUploadTaskCreated) {
  const uploadId = uploadUrl.split('/').pop()
  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      endpoint: `${process.env.VUE_APP_BASE_API}`,
      uploadUrl: `${process.env.VUE_APP_BASE_API}${uploadUrl.split('/api')[1]}`,
      headers: {
        'Authorization': `Bearer ${store.getters.getTokenInfo.accessToken}`
      },
      chunkSize: window.Cypress ? 25600 : 3670016, // 25KB for Cypress, 3.5MB default
      retryDelays: [0, 1000, 3000],
      onShouldRetry: async (error, attempts, options) => {
        // if token is expired (status === 401), refresh it and retry
        if (error.originalResponse && error.originalResponse.getStatus() === 401) {
          log.warn(`Tus upload failed due to invalid token... (Total attempts ${attempts})`)
          // refresh token and retry
          try {
            const token = await dispatchCheckAccessToken()
            options.headers['Authorization'] = `Bearer ${token}`
            return true
          } catch (e) {
            log.warn(`Unexpected error while retrying: ${e} (Total attempts ${attempts})`)
            return false
          }
        } else {
          return false
        }
      },
      onError: (err) => {
        // Allow retries when access token is expired (status === 401).
        // reject in all other cases
        if (!err.originalResponse || err.originalResponse.getStatus() !== 401) {
          commit('REMOVE_UPLOAD_PROGRESS', uploadId)
          reject(err)
        }
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        // after each chunk is uploaded, we can get the total size and the uploaded size
        if (uploadId && rootGetters.videoUploadProgressInfo.has(uploadId)) {
          commit('UPDATE_UPLOAD_PROGRESS', {
            uploadId,
            totalSize: bytesTotal,
            uploadedSize: bytesUploaded,
            secondsRemaining: rootGetters.videoUploadProgressInfo.get(uploadId).secondsRemaining,
            isPaused: rootGetters.videoUploadProgressInfo.get(uploadId).isPaused,
            cancelRequest: rootGetters.videoUploadProgressInfo.get(uploadId).cancelRequest,
            uploadTask: rootGetters.videoUploadProgressInfo.get(uploadId).uploadTask
          })
        }
      },
      onBeforeRequest: async (req) => {
        // ensure access token is valid before each request
        const token = await dispatchCheckAccessToken(20000)
        upload.options.headers['Authorization'] = `Bearer ${token}`
      },
      onAfterResponse: (req, res) => {
        // on response to PATCH request (after each chunk is uploaded), we can get the estimated time remaining
        // that was calculate by webservice
        const body = res.getBody()
        if (!body) {
          return
        }

        const latestEstimate = getProgressEstimate(body, uploadId, rootGetters)
        if (latestEstimate !== -1) {
          commit('UPDATE_UPLOAD_PROGRESS', {
            uploadId,
            totalSize: rootGetters.videoUploadProgressInfo.get(uploadId).totalSize,
            uploadedSize: rootGetters.videoUploadProgressInfo.get(uploadId).uploadedSize,
            secondsRemaining: latestEstimate,
            isPaused: rootGetters.videoUploadProgressInfo.get(uploadId).isPaused,
            cancelRequest: rootGetters.videoUploadProgressInfo.get(uploadId).cancelRequest,
            uploadTask: rootGetters.videoUploadProgressInfo.get(uploadId).uploadTask
          })
        }
      },
      onSuccess: async () => {
        const uploadInfo = await simseiApi.get(`tus/upload/info?uri=${upload.url}`)
        if (uploadInfo && !upload.uploadInProgress && !upload.isExpired) {
          commit('REMOVE_UPLOAD_PROGRESS', uploadId)
          resolve(uploadInfo)
        } else {
          reject(new Error('Upload is not finished yet!'))
        }
      }
    })
    upload.start()
    onUploadTaskCreated(upload)
  })
}

export default {
  state,
  getters,
  actions,
  mutations
}
