const getDefaultState = () => {
  return {
    surgicalData: {
      approach: '',
      procedure: ''
    },
    otherVideoTitle: ''
  }
}

const state = getDefaultState()

const getters = {
  getSurgicalApproach: state => {
    return state.surgicalData.approach
  },
  getSurgicalProcedure: state => {
    return state.surgicalData.procedure
  },
  getOtherVideoTitle: state => {
    return state.otherVideoTitle
  }
}

const actions = {
  setSurgicalApproachProcedure: ({ commit }, surgicalInfo) => {
    surgicalInfo.procedure = surgicalInfo.procedure.trim()
    commit('SET_SURGICAL_DATA', surgicalInfo)
  },
  setOtherVideoTitle: ({ commit }, videoTitle) => {
    videoTitle = videoTitle.trim()
    commit('SET_OTHER_TITLE', videoTitle)
  },
  resetSubmissionState: ({ commit }) => {
    commit('RESET_SUBMISSION_STATE')
  }
}

const mutations = {
  SET_SURGICAL_DATA (state, surgicalData) {
    state.surgicalData = surgicalData
  },
  SET_OTHER_TITLE (state, videoTitle) {
    state.otherVideoTitle = videoTitle
  },
  RESET_SUBMISSION_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
