import { simseiApi } from '@/residency/app-props'

const getDefaultState = () => {
  return {
    allAndriodVersions: [],
    allIosVersions: []
  }
}

const state = getDefaultState()

const getters = {
  allAndriodVersions (state) {
    return state.allAndriodVersions
  },
  latestAndroidVersion (state) {
    return state.allAndriodVersions[0]
  },
  allIosVersions (state) {
    return state.allIosVersions
  },
  latestIosVersion (state) {
    return state.allIosVersions[0]
  }
}

const actions = {
  async getAndroidVersions ({ commit }) {
    const resp = await simseiApi.get('/mobile/android/version-history')
    commit('SET_ANDRIOD_MOBILE_APP_VERSIONS', resp.data)
  },
  async getIosVersions ({ commit }) {
    const resp = await simseiApi.get('/mobile/ios/version-history')
    commit('SET_IOS_MOBILE_APP_VERSIONS', resp.data)
  },
  async deleteAndriodVersion ({ commit }, id) {
    return await simseiApi.delete(`/mobile/android/delete-version/${id}`)
  },
  async deleteIosVersion ({ commit }, id) {
    return await simseiApi.delete(`/mobile/ios/delete-version/${id}`)
  }
}

const mutations = {
  SET_ANDRIOD_MOBILE_APP_VERSIONS (state, versions) {
    state.allAndriodVersions = versions
  },
  SET_IOS_MOBILE_APP_VERSIONS (state, versions) {
    state.allIosVersions = versions
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
