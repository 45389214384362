import { simseiApi } from '@/residency/app-props'
import { OTHER, TECHNICAL_ASSESSMENT } from '@/residency/consts/assessment-types.js'
import log from '@/residency/utils/log'

const getDefaultState = () => {
  return {
    isValidAsmt: true,
    asmt: {
      course: {
        id: ''
      },
      assessedUser: {
        id: null
      },
      activityForm: {
        id: '',
        name: '',
        type: '',
        questions: [],
        isTimed: true
      },
      assessmentType: TECHNICAL_ASSESSMENT,
      skillLevel: '',
      form: {
        secondsToComplete: null,
        feedback: ''
      },
      videoAsmtId: '',
      isOfficialReview: false
    }
  }
}

const state = getDefaultState()

const getters = {
  grsAsmt: state => state.asmt,
  isValidAsmt: state => state.isValidAsmt
}

const actions = {
  loadAsmt: async ({ dispatch }, { asmtId, activityType = 'grs' }) => {
    // Fetch the user-filled out asmt form in the mongo db
    const asmtFormPromise = dispatch('getAsmtForm', {
      activityType: activityType,
      asmtId: asmtId
    })
    // Fetch the asmt details from the `assessment` table in the SQL db
    const asmtDetailsPromise = dispatch('getAsmtDetails', asmtId)

    const [asmtForm, asmtSql] = await Promise.all([asmtFormPromise, asmtDetailsPromise])
    const asmt = asmtForm.data.form

    sortAsmtQuestions(asmt, activityType)

    const asmtDetails = asmtSql.data
    return { asmt, asmtDetails }
  },
  isGrsAsmtValid: ({ commit, rootGetters }) => {
    const asmt = rootGetters.grsAsmt
    if (!asmt.skillLevel) {
      commit('SET_VALID_ASMT', false)
      return false
    }

    for (const question of asmt.activityForm.questions) {
      if (!question.options || question.options.length === 0) {
        commit('SET_VALID_ASMT', false)
        return false
      }
    }

    commit('SET_VALID_ASMT', true)
    return true
  },
  hasCategoryError: ({ commit, rootGetters }, index) => {
    // Checks that the question/grs category specified by index has an an answer selected
    const asmt = rootGetters.grsAsmt
    const question = asmt.activityForm.questions[index]
    if (question.options.length === 0) {
      return true
    }

    return false
  },
  getAsmtForm: ({ commit }, { activityType, asmtId }) => {
    return simseiApi.get(`/${activityType}-form/${asmtId}`)
  },
  getAsmtDetails: ({ commit }, asmtId) => {
    return simseiApi.get(`/assessment/${asmtId}`)
  },
  getCognitiveAsmt: async ({ commit, rootGetters }) => {
    const res = await simseiApi.get('/cognitive-assessment/search/findByCourseId', {
      params: {
        courseId: rootGetters.selectedCourse.id,
        projection: 'inlineCognitiveAsmtQuestions'
      }
    })
    return res.data
  },
  postCognitiveAsmt: ({ commit }, cognitiveAssessmentDto) => {
    return simseiApi.post('/cognitive-assessment', cognitiveAssessmentDto)
  },
  getGrsForm: async ({ commit }, { videoType, formId }) => {
    const res = await simseiApi.get(`/grs/${videoType}/${formId}`)

    const activityForm = {}
    activityForm.name = res.data.activity?.name
    activityForm.isTimed = res.data.isTimed
    activityForm.type = res.data.type
    activityForm.questions = []

    res.data.questions.forEach(question => {
      activityForm.questions.push(parseQuestion(question))
    })

    activityForm.questions.sort((a, b) => {
      return a.order - b.order
    })

    return activityForm
  },
  submitGrsAsmt: ({ commit, rootGetters }, { residentId, videoType, feedback, isSharedRequested }) => {
    const asmt = rootGetters.grsAsmt
    asmt.assessedUser.id = residentId
    asmt.isOfficialReview = rootGetters.isInstructor
    asmt.isRequestedForSharedLibrary = isSharedRequested
    asmt.recommendedVideos = rootGetters.videoFeedbackRecommendedVideos
    asmt.comments = rootGetters.videoFeedbackComments
    asmt.surgicalForm = asmt.activityForm // needed for surgical payload only

    if (feedback) {
      asmt.form.feedback = feedback
    }

    return simseiApi.post(`/${videoType}/assessment/grs`, asmt)
  },
  submitOtherAsmt: ({ commit, rootGetters }, { videoAsmtId, residentId, isSharedRequested }) => {
    const asmt = {
      assessedUser: { id: residentId },
      isOfficialReview: rootGetters.isInstructor,
      isRequestedForSharedLibrary: isSharedRequested,
      comments: rootGetters.videoFeedbackComments,
      videoAsmtId,
      assessmentType: OTHER
    }

    return simseiApi.post('/assessment/other', asmt)
  },
  setGrsAsmtCategory: ({ commit, rootGetters }, { index, selectedId }) => {
    const asmt = rootGetters.grsAsmt
    const questions = asmt.activityForm.questions
    questions[index].options = [{
      id: selectedId
    }]
  },
  setGrsAsmtSkillLevel: ({ commit, rootGetters }, skillLevel) => {
    const asmt = rootGetters.grsAsmt
    asmt.skillLevel = skillLevel
  },
  setGrsAsmtTime: ({ commit, rootGetters }, secondsToComplete) => {
    const asmt = rootGetters.grsAsmt
    asmt.form.secondsToComplete = secondsToComplete
  },
  resetGrsAsmt: ({ commit }) => {
    commit('RESET_GRS_ASMT')
  }
}

const mutations = {
  START_GRS_ASMT (state, { courseId, activityFormId, activityForm, secondsToComplete, videoAsmtId }) {
    this.commit('RESET_GRS_ASMT')
    this.commit('FILL_GRS_ASMT', { courseId, activityFormId, activityForm, secondsToComplete, videoAsmtId })
  },
  SET_ASMT (state, asmt) {
    state.asmt = asmt
  },
  SET_VALID_ASMT (state, valid) {
    state.isValidAsmt = valid
  },
  FILL_GRS_ASMT (state, { courseId, activityFormId, activityForm, secondsToComplete, videoAsmtId }) {
    const asmt = getDefaultState().asmt
    asmt.course.id = courseId
    asmt.activityForm.id = activityFormId
    asmt.activityForm.type = activityForm.type
    asmt.form.secondsToComplete = secondsToComplete

    if (videoAsmtId) {
      asmt.videoAsmtId = videoAsmtId
    }

    for (const question of activityForm.questions) {
      asmt.activityForm.questions.push({
        id: question.questionId,
        options: []
      })
    }

    state.asmt = asmt
  },
  RESET_GRS_ASMT (state) {
    Object.assign(state, getDefaultState())
  }
}

function parseQuestion (question) {
  const parsedQuestion = {
    questionId: question.id,
    title: question.question,
    order: question.order,
    options: []
  }
  question.options.forEach(option => {
    parsedQuestion.options.push({
      optionId: option.id,
      value: option.value,
      description: option.text,
      order: option.order
    })
  })

  parsedQuestion.options.sort((a, b) => {
    return a.order - b.order
  })
  return parsedQuestion
}

export function sortAsmtQuestions (asmt, activityType) {
  switch (activityType) {
    case 'grs':
      sortGrs(asmt.questions)
      break
    case 'task-checklist':
      sortTaskChecklist(asmt.questions)
      break
    case 'cognitive-assessment':
      break
    case 'reflection':
      break
    case 'debrief':
      break
    default:
      log.error('Unknown activity type ' + activityType)
  }
}

function sortGrs (questions) {
  questions.sort((a, b) => {
    return a.order - b.order
  })

  for (const question of questions) {
    question.options.sort((a, b) => {
      return a.order - b.order
    })
  }
}

function sortTaskChecklist (questions) {
  questions.sort((a, b) => {
    return a.order - b.order
  })
}

export default {
  state,
  getters,
  actions,
  mutations
}
