import { simseiApi } from '@/residency/app-props'
import { systemSelectionTypes } from '@/residency/views/admin/admin-enum'

const getDefaultState = () => {
  return {
    systems: [],
    activePrograms: [],
    inactivePrograms: [],
    adminSelectedProgram: null,
    dneSystemId: null,
    nonDeprecatedActivities: null
  }
}

const state = getDefaultState()

const getters = {
  systems (state) {
    return state.systems
  },
  activePrograms (state) {
    return state.activePrograms
  },
  inactivePrograms (state) {
    return state.inactivePrograms
  },
  adminSelectedProgram (state) {
    return state.adminSelectedProgram
  },
  dneSystemId (state) {
    return state.dneSystemId
  },
  nonDeprecatedActivities (state) {
    return state.nonDeprecatedActivities
  }
}

const actions = {
  getSystemsData: async ({ dispatch, commit }) => {
    const response = await simseiApi.get('/system?projection=inlineSystemInfo')
    const systems = response.data._embedded.system
    commit('SET_SYSTEMS', systems)
    await dispatch('setActiveAndInactivePrograms')
    dispatch('setDneSystemId', systems)
    return systems
  },
  getSystemDetails: async ({ commit }, systemId) => {
    const response = await simseiApi.get(`/system/${systemId}`, {
      params: {
        projection: 'inlineSystemInfo'
      }
    })
    return response.data
  },
  fetchProgramDetailsForEdit: async ({ commit }, programId) => {
    const program = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
    const specialtyRes = await simseiApi.get(`/program/getSpecialty/${programId}`)
    const res = await simseiApi.get(`/program/program-director/${programId}`)
    const activeProgramDirectors = res.data.filter(direcor => !direcor.isExpired)
    return {
      programName: program.data.title,
      programAddresses: program.data.programAddresses,
      timeZone: program.data.timezoneOption.timezone,
      specialty: specialtyRes.data,
      programDirector: activeProgramDirectors,
      accountNumber: program.data.accountNumber,
      implementationUserId: program.data.implementationUserId,
      fieldTeamUserId: program.data.fieldTeamUserId
    }
  },
  getProgramDetails: async ({ commit, getters }, programId) => {
    if (getters.adminSelectedProgram?.id === Number(programId)) {
      return getters.adminSelectedProgram
    }
    const response = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
    commit('SET_ADMIN_SELECTED_PROGRAM', response.data)
    return response.data
  },
  forceRefreshProgramDetails: async ({ commit }, programId) => {
    const response = await simseiApi.get(`/program/${programId}?projection=inlineProgramDetails`)
    commit('SET_ADMIN_SELECTED_PROGRAM', response.data)
    return response.data
  },
  updateUser: ({ commit }, user) => {
    return simseiApi.patch('/user/' + user.id, user)
  },
  updateUserGroupCourse: ({ commit }, { groupCourseId, showOrHide, activityId }) => {
    return simseiApi.patch(`/user-group-course/${groupCourseId}/${showOrHide}/${activityId}`)
  },
  getUserGroups: async ({ commit }, programId) => {
    const response = await simseiApi.get(`/user-group/program/${programId}`)
    return response.data
  },
  createSystem: ({ commit }, system) => {
    return simseiApi.post('/system', system)
  },
  updateInstitution: ({ commit }, institution) => {
    return simseiApi.patch(`/institution/${institution.id}`, institution)
  },
  setActiveAndInactivePrograms: async ({ commit }) => {
    const activePrograms = []
    const inactivePrograms = []
    const response = await simseiApi.get('/program?projection=inlineProgramDetails')
    const programs = response.data._embedded.program

    programs.forEach(program => {
      if (program.activeProgram) {
        activePrograms.push(program)
      } else {
        inactivePrograms.push(program)
      }
    })
    commit('SET_ACTIVE_PROGRAMS', activePrograms)
    commit('SET_INACTIVE_PROGRAMS', inactivePrograms)
  },
  addIntitution: async ({ commit }, payload) => {
    return simseiApi.post('/institution', payload)
  },
  createProgram: ({ commit }, payload) => {
    return simseiApi.post(`/program?sendRegistrationEmail=${payload.sendRegistrationEmail}`, payload.program)
  },
  editProgram: ({ commit }, program) => {
    return simseiApi.post('/program/edit', program)
  },
  deleteSystemCoord: async ({ commit }, coordinator) => {
    return simseiApi.delete(`/user/${coordinator.id}`)
  },
  setDneSystemId: ({ commit }, systems) => {
    const dneSystem = systems.find(system => system.systemName === systemSelectionTypes.DNE)
    commit('SET_DNE_SYSTEM_ID', dneSystem.id)
  },
  fetchNonDeprecatedActivities: async ({ commit }) => {
    const activitiesRes = await simseiApi.get('activity/search/findAllByIsDeprecated', {
      params: {
        isDeprecated: false,
        projection: 'inlineActivityNameAndId'
      }
    })

    const activities = activitiesRes.data._embedded.activity
    activities.forEach((activity) => delete activity['_links'])
    activities.sort((a, b) => a.name.localeCompare(b.name))
    commit('SET_NON_DEPRECATED_ACTIVITIES', activities)
  },
  resetNonDeprecatedActivities: ({ state }) => {
    if (!state.nonDeprecatedActivities) return

    state.nonDeprecatedActivities.forEach((activity) => {
      activity.activityType = null
    })
  },
  clearAdminState: ({ commit }) => {
    commit('CLEAR_ADMIN_STATE')
  }
}

const mutations = {
  SET_SYSTEMS (state, payload) {
    state.systems = payload
  },
  SET_ACTIVE_PROGRAMS (state, payload) {
    state.activePrograms = payload
  },
  SET_INACTIVE_PROGRAMS (state, payload) {
    state.inactivePrograms = payload
  },
  SET_ADMIN_SELECTED_PROGRAM (state, payload) {
    state.adminSelectedProgram = payload
  },
  SET_DNE_SYSTEM_ID (state, payload) {
    state.dneSystemId = payload
  },
  SET_NON_DEPRECATED_ACTIVITIES (state, payload) {
    state.nonDeprecatedActivities = payload
  },
  CLEAR_ADMIN_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
