import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    userDetails: null
  }
}

const state = getDefaultState()

const getters = {
  getUserDetails (state) {
    return state.userDetails
  }
}

const actions = {
  async fetchMgmtFacultyDetails ({ commit }, userId) {
    const res = await workshopApi.get(`/workshop-user/${userId}?projection=facultyUserDetails`)
    commit('SET_USER_DETAILS', res.data)
  },
  async fetchMgmtAttendeeDetails ({ commit }, userId) {
    const res = await workshopApi.get(`/workshop-user/${userId}?projection=attendeeUserDetails`)
    commit('SET_USER_DETAILS', res.data)
  },
  async fetchMgmtFacultyAttendeeDetails ({ commit }, userId) {
    const res = await workshopApi.get(`/workshop-user/${userId}?projection=facultyAttendeeUserDetails`)
    commit('SET_USER_DETAILS', res.data)
  }
}

const mutations = {
  SET_USER_DETAILS (state, details) {
    state.userDetails = details
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
