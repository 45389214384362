import { DEFAULT_OPTIONS } from '@/consts/filter-default-option'

const DEFAULT_FILTER = Object.freeze({
  courseName: DEFAULT_OPTIONS.ALL,
  fullName: DEFAULT_OPTIONS.ALL,
  userGroup: DEFAULT_OPTIONS.ALL,
  userRole: DEFAULT_OPTIONS.ALL
})

const defaultState = () => {
  return {
    textSearchFilter: '',
    filterMenu: DEFAULT_FILTER
  }
}

const state = defaultState()

export const getters = {
  textSearchFilter (state) {
    return state.textSearchFilter
  },
  filterMenu (state) {
    return state.filterMenu
  }
}

export const actions = {
  setTextSearchFilter: ({ commit }, filterValue) => {
    commit('SET_TEXT_SEARCH_FILTER', filterValue)
  },
  setFilterMenu: ({ commit }) => {
    commit('SET_FILTER_MENU')
  },
  updateFilterMenu: ({ commit }, { filterType, filterValue }) => {
    commit('UPDATE_FILTER_MENU', { filterType, filterValue })
  },
  resetFilterMenu: ({ commit }) => {
    commit('RESET_FILTER_MENU')
  }
}

export const mutations = {
  SET_TEXT_SEARCH_FILTER (state, filterValue) {
    state.textSearchFilter = filterValue
  },
  SET_FILTER_MENU (state) {
    const defaultFilterCopy = JSON.parse(JSON.stringify(DEFAULT_FILTER))
    state.filterMenu = defaultFilterCopy
  },
  UPDATE_FILTER_MENU (state, { filterType, filterValue }) {
    state.filterMenu[filterType] = filterValue
  },
  RESET_FILTER_MENU (state) {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
