import { simseiApi, dispatchCheckAccessToken } from '@/residency/app-props'
import { buildBufferProxy, retransmitMissingMessages, getBufferSize, resetBuffer } from '@/residency/websocket/msg-buffer'
import Stomp from 'webstomp-client'
import { md5 } from 'js-md5'
import log from '@/residency/utils/log'
import RecordRTC from 'recordrtc'

let uploadChecker

/**
 * Initializes a websocket connection.
 * Websocket will retry to connect if webservice goes down or their internet is cut.
 */
const connectSocket = (initial, rootGetters, commit, dispatch, streamType) => {
  const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_API)

  // Heartbeat of 25 seconds "is in line with the following IETF recommendation for public Internet applications."
  // https://docs.spring.io/spring-framework/docs/4.1.0.RC1/spring-framework-reference/html/websocket.html
  let ws = Stomp.over(socket, { debug: false, heartbeat: { incoming: 25000, outgoing: 25000 } })
  // Wrap websocket with buffer proxy to handle message buffering/resending
  ws = buildBufferProxy(ws, streamType)

  return new Promise((resolve, reject) => {
    ws.connect(
      { Authorization: rootGetters.getTokenInfo.accessToken },
      () => {
        ws.streamType = streamType
        resolve(ws)
        log.info(`Video WS (${streamType}) has connected for user ${rootGetters.me.id}`)
        if (!initial) {
          // Retry blob upload only if websocket disconnected previously
          console.info('Video WS has reconnected!')
          commit('SET_STREAM_WS', { streamType, ws })
          commit('SET_STREAM_WS_CONNECTED', streamType)
          dispatch('uploadBlob', { streamType })
        }

        // Retransmit any messages that were buffered and not sent
        const numOfRetransmittedMessages = retransmitMissingMessages(ws, streamType)
        if (numOfRetransmittedMessages > 0) {
          log.info(`Retransmitted ${numOfRetransmittedMessages} buffered messages`)
        }
      },
      (err) => {
        reject(err)
        commit('SET_STREAM_WS_RECONNECTING', streamType)
        log.warn(`Video WS (${streamType}) has disconnected for user ${rootGetters.me.id}`)
        // Reconnect websocket after 1s
        setTimeout(() => {
          console.info(`Video WS (${streamType}) disconnected. Reconnecting...`)
          connectSocket(false, rootGetters, commit, dispatch, streamType)
        }, 1000)
      }
    )
  })
}

const getDefaultState = () => {
  return {
    recordId: null,
    streams: {},
    reservedStreamIds: {},
    previousRecordTime: 0,
    totalByteSize: 0,
    remainingBufferSize: 0,
    uploadPercent: 0,
    backgroundUploading: false,
    finalBlobReceiptIds: {},
    cancelingVideoUpload: false
  }
}

const state = getDefaultState()

const defaultStreamState = () => {
  return {
    id: null,
    deviceId: null,
    uploadQueue: [],
    queueIndex: 0,
    uploading: false,
    stoppedRecording: false,
    sentFinalBlob: false,
    ws: null,
    videoLength: 0,
    reconnecting: false,
    streamInterrupted: false,
    byteCount: 0,
    md5checksum: null,
    digestedChecksum: null,
    fullVideoChecksum: null,
    fullVideoBlob: null
  }
}

const getters = {
  usedDevices (state) {
    return Object.values(state.streams).map(stream => stream && stream.deviceId)
  },
  currentRecordTime (state) {
    return state.previousRecordTime
  },
  reservedStreamIds (state) {
    return state.reservedStreamIds
  },
  backgroundUploadPercent (state) {
    return state.uploadPercent
  },
  backgroundUploading (state) {
    return state.backgroundUploading
  },
  finalBlobReceiptIds (state) {
    return state.finalBlobReceiptIds
  },
  activeStreamCount (state) {
    return state.streams ? Object.keys(state.streams).length : 0
  },
  cancelingVideoUpload (state) {
    return state.cancelingVideoUpload
  }
}

const actions = {
  /**
   * Create a video assessment row in the database and return its ID
   */
  createVideoAsmt: async ({ commit }, payload) => {
    const resp = await simseiApi.post(`/video/${payload.videoType}/start-record`, payload.videoInfo)
    const uuid = resp.data
    commit('SET_RECORD_ID', uuid)
    return uuid
  },
  /**
   * Set the laparoscope or facecam stream state, ie state.streams.FACECAM or state.streams.LAPAROSCOPE.
   * These objects are defined by the `defaultStreamState` above.
   */
  createStream: ({ commit }, streamInfo) => {
    commit('CREATE_STREAM', streamInfo)
  },
  /**
   * Delete the laparoscope or facecam stream state, ie state.streams.FACECAM or state.streams.LAPAROSCOPE.
   */
  removeStreamType: ({ commit }, streamType) => {
    commit('REMOVE_STREAM', streamType)
    commit('RESET_UPLOAD_PERCENT')
  },
  /**
   * Create video stream row in the database and connect a websocket to upload the stream over
   */
  startStream: async ({ commit, dispatch, rootGetters }, {
    streamType, frameRate, deviceId, mimeType, shouldGenerateThumbnails
  }) => {
    let params = `recordId=${state.recordId}`
    params += `&streamType=${streamType}`
    params += `&frameRate=${frameRate}`
    params += `&deviceId=${deviceId}`
    params += `&mimeType=${mimeType}`
    params += `&needsThumbnails=${shouldGenerateThumbnails}`
    const resp = await simseiApi.post(`/video/start-stream?${params}`)
    const streamId = resp.data

    await dispatchCheckAccessToken()
    resetBuffer() // Reset buffer to prevent old data from being sent on reconnect
    const ws = await connectSocket(true, rootGetters, commit, dispatch, streamType)

    commit('START_STREAM', { streamType, streamId, ws })
  },
  /**
   * Queue blob to be uploaded to the back end
   */
  queueBlob: ({ dispatch, commit }, blobInfo) => {
    commit('QUEUE_BLOB', blobInfo)
    dispatch('uploadBlob', { streamType: blobInfo.streamType })
    commit('UPDATE_RECORD_LENGTH')
    commit('UPDATE_TOTAL_SIZE', blobInfo.blob.size)
  },
  /**
   * Set stoppedRecording on the stream and upload final blob
   */
  endStream: async ({ dispatch, commit, state }, { streamType, fullVideoBlob }) => {
    state.streams[streamType].fullVideoBlob = fullVideoBlob
    await dispatch('computeComparisonChecksum', { streamType, fullVideoBlob })
    commit('END_STREAM', streamType)
    dispatch('uploadBlob', { streamType })
  },
  /**
   * Computes the checksum from the ENTIRE recorded video file. We can use this
   * checksum to compare with the checksum of all transmitted data.
   */
  computeComparisonChecksum: async ({ commit }, { streamType, fullVideoBlob }) => {
    const videoBinaryData = await fullVideoBlob.arrayBuffer()
    const checksum = md5.hex(Buffer.from(videoBinaryData))
    commit('STREAM_FULL_FILE_CHECKSUM', { streamType, checksum })
  },
  /**
   * Update video length
   */
  updateTime: ({ commit }, videoInfo) => {
    commit('UPDATE_STREAM_TIME', videoInfo)
  },
  /**
   * Upload blob from the state.streams.<stream state>.uploadQueue
   */
  uploadBlob: async ({ dispatch, commit, state, rootGetters }, { streamType, force }) => {
    if (rootGetters.cancelingVideoUpload) {
      return
    }

    const streamState = state.streams[streamType]
    if (!streamState || !streamState.ws) {
      log.error('streamState or streamState.ws null while uploading a blob')
      return
    }

    // This ensures that the blob won't be uploaded twice
    if (!force && streamState.uploading) return

    // Stop uploading if ws disconnected
    if (!streamState.ws.connected) {
      streamState.uploading = false
      log.error('Websocket connection disconnected while uploading')
      return
    }

    streamState.uploading = true
    const blobToUpload = streamState.uploadQueue[streamState.queueIndex]

    if (!blobToUpload) {
      if (streamState.stoppedRecording) {
        dispatch('sendFinalBlob', { streamState, streamType, attempt: 0 })
        return
      } else {
        streamState.uploading = false
        return
      }
    }

    try {
      // per this example: https://websockets.spec.whatwg.org/#buffered-amount-example
      // this is the number of bytes of data that have been queued using calls to send()
      // but not yet transmitted to the network.
      // Therefore, by only sending messages when bufferedAmount is zero, we can ensure that
      // we send data about every 100ms OR as fast as the network can handle it.
      if (streamState.ws.ws.bufferedAmount === 0) {
        // Converts blob into a base64 string
        const arrayBuffer = await blobToUpload.arrayBuffer()
        const buf = Buffer.from(arrayBuffer)
        const base64 = buf.toString('base64')

        // Compute checksum to compare sent data to data received on the backend
        if (streamState.md5checksum) {
          streamState.byteCount += buf.length
          streamState.md5checksum.update(buf)
        }

        // Constructs json and send to webservice via websockets
        const data = JSON.stringify({
          id: streamState.id,
          segment: streamState.queueIndex,
          base64: base64,
          stream: streamType,
          done: false,
          programId: rootGetters.programId
        })
        if (streamState.ws.ws.readyState === WebSocket.OPEN) {
          streamState.ws.send('/app/video', data)
          commit('REDUCE_REMAINING_BUFFER_SIZE', arrayBuffer.byteLength)

          delete streamState.uploadQueue[streamState.queueIndex]
          streamState.queueIndex++
        }

        if (streamState.sentFinalBlob) {
          log.warn('Upload called after final blob sent')
        }
      }
      setTimeout(() => dispatch('uploadBlob', { streamType, force: true }), 100)
    } catch (err) {
      log.error(err)
      streamState.uploading = false
      dispatch('retryUpload', streamType)
    }
    commit('UPDATE_RECORD_LENGTH')
  },
  /**
   * After user stops recording, send the final blob to notify the back end that the recording is complete
   */
  sendFinalBlob: async ({ commit, dispatch, rootGetters }, { streamState, streamType, attempt }) => {
    // TODO: we can remove the `attempt` parameter here if we don't detect the condition below
    // after a significant amount of time (as of 5/14/2024)
    if (attempt !== 0 && attempt % 50 === 0) {
      // if attempt is unusually high, this might indicate there is a bug in the conditions above
      log.warn(`Attempted to send final blob for stream ${streamState.id} at least ${attempt} times`)
    }

    if (hasBufferedData(streamState, streamType) || streamState.ws.ws.readyState !== WebSocket.OPEN) {
      // let the buffer and upload queue clear before sending the final packet
      setTimeout(() => dispatch('sendFinalBlob', { streamState, streamType, attempt: attempt + 1 }), 100)
      return
    }
    try {
      if (!streamState.digestedChecksum) {
        streamState.digestedChecksum = streamState.md5checksum.hex()
      }

      // alert when data transmitted over websocket does not match recorded file
      if (streamState.digestedChecksum !== streamState.fullVideoChecksum) {
        log.warn(`Transmitted checksum does not match local file's checksum! (stream ${streamState.id})`)
      }

      const data = {
        id: streamState.id,
        done: true,
        streamInterrupted: streamState.streamInterrupted,
        md5ChecksumHexStr: streamState.digestedChecksum,
        totalByteCount: streamState.byteCount,
        stream: streamType,
        programId: rootGetters.programId
      }
      // Wait for the final blob to be processed by waiting for the receipt
      // For more info, see this wiki page:
      // https://gitlab.simseidev.com/web-development/simsei/deployment-setup/-/wikis/WebSocket-heatbeat-and-receipts
      const expectedReceiptId = `final-blob-${streamState.id}`
      commit('ADD_RECEIPT_ID', expectedReceiptId)
      streamState.ws.onreceipt = (frame) => {
        const processedReceiptId = frame.headers['receipt-id']
        commit('REMOVE_RECEIPT_ID', processedReceiptId)
      }
      dispatch('attemptSendFinalBlob', { streamState, streamType, data, expectedReceiptId, attempt: 0 })
    } catch (err) {
      this.$log.error(err)
      streamState.uploading = false
      dispatch('retryUpload', streamType)
    }
  },
  attemptSendFinalBlob: async (
    { commit, dispatch, rootGetters },
    { streamState, streamType, data, expectedReceiptId, attempt }
  ) => {
    try {
      // determine if we have tried to resend the final blob too many times
      if (rootGetters.finalBlobReceiptIds[expectedReceiptId] && attempt >= 6) {
        log.error(`Failed to send final blob to webservice after 6 attempts for stream ${streamState.id}.`)
        commit('REMOVE_RECEIPT_ID', expectedReceiptId)
        return
      }

      if (streamState.ws.ws.readyState === WebSocket.OPEN) {
        streamState.ws.send('/app/video', JSON.stringify(data), {
          'receipt': expectedReceiptId
        })
        commit('SENT_FINAL_BLOB', streamType)
      } else {
        log.warn(`Attempted to send final blob to webservice but websocket is not open for stream ${streamState.id}`)
      }
      // schedule callback to retry final blob send if we don't get a receipt
      // after some time
      setTimeout(() => {
        if (rootGetters.finalBlobReceiptIds[expectedReceiptId]) {
          dispatch('attemptSendFinalBlob', { streamState, streamType, data, expectedReceiptId, attempt: ++attempt })
        }
      }, 5000)
    } catch (err) {
      log.error(err)
      streamState.uploading = false
      dispatch('retryUpload', streamType)
    }
  },
  removeStream: ({ state, commit }, streamInfo) => {
    const streamState = state.streams[streamInfo.streamType]
    if (!streamState || !streamState.ws) return

    if (streamInfo.streamId === streamState.id) {
      commit('REMOVE_STREAM', streamInfo.streamType)
    }
  },
  retryUpload: ({ dispatch }, streamType) => {
    log.info('Retrying to upload failed blob')
    setTimeout(() => {
      dispatch('uploadBlob', { streamType })
    }, 1000)
  },
  /**
   * Check the completed upload percentage to inform the user of the upload progress
   */
  startUploadChecker: ({ state }) => {
    state.backgroundUploading = true
    if (uploadChecker) clearInterval(uploadChecker)

    // Websockets used to stream video. There currently can be a maximum of two: Laparoscopic and Facecam
    const streams = Object.values(state.streams)

    uploadChecker = setInterval(() => {
      if (state.totalByteSize === 0) return

      let wsEnded = true
      let currentBufferedAmount = 0
      // Check if both websocket connections have finished streaming
      streams.forEach(stream => {
        const socket = stream.ws.ws

        if (socket.readyState !== WebSocket.CLOSED || stream.reconnecting) wsEnded = false
        currentBufferedAmount += socket.bufferedAmount
      })

      if (wsEnded) {
        clearInterval(uploadChecker)
        state.backgroundUploading = false
        state.uploadPercent = 1
      } else {
        const uploadPercent = 1 - (state.remainingBufferSize + currentBufferedAmount) / state.totalByteSize
        state.uploadPercent = uploadPercent
      }
    }, 500)
  },
  /**
   * Cancel the video recording
   */
  cancelRecording: ({ commit, state, dispatch }, videoType) => {
    state.backgroundUploading = false
    simseiApi.delete(`/video/${videoType}/cancel/${state.recordId}`)
    Object.values(state.streams).forEach(stream => {
      if (stream.ws) {
        stream.ws.disconnect()
      }
    })
  },
  pauseRecordUpload: ({ state }) => {
    state.cancelingVideoUpload = true
  },
  resumeRecordUpload: ({ state, dispatch }) => {
    state.cancelingVideoUpload = false
    for (const streamType in state.streams) {
      dispatch('uploadBlob', { streamType, force: true })
    }
  },
  /**
   * Reset the state back to its default
   */
  clearVideoRecorder: ({ commit }) => {
    commit('RESET_VIDEO_RECORDER')
  },
  /**
   * Reserve the video recording device after its been selected by the user
   */
  reserveStream: ({ commit }, streamId) => {
    commit('RESERVE_STREAM_ID', streamId)
  },
  /**
   * Unreserve the video recording device to make it available for selection again
   */
  unreserveStream: ({ commit }, streamId) => {
    commit('UNRESERVE_STREAM_ID', streamId)
  },
  downloadRecordedVideo: async ({ state }, { streamType, videoTitle }) => {
    const stream = state.streams[streamType]
    const fileExt = getVideoExtension(stream.fullVideoBlob.type)
    RecordRTC.invokeSaveAsDialog(stream.fullVideoBlob, `${videoTitle}.${fileExt}`)
  }
}

const mutations = {
  SET_RECORD_ID (state, recordId) {
    state.recordId = recordId
  },
  CREATE_STREAM (state, { streamType, deviceId }) {
    let streamState
    if (state.streams[streamType]) {
      streamState = state.streams[streamType]
    } else {
      streamState = defaultStreamState()
    }
    streamState.deviceId = deviceId
    state.streams[streamType] = streamState
  },
  async START_STREAM (state, { ws, streamType, streamId }) {
    const streamState = state.streams[streamType]
    streamState.id = streamId
    streamState.ws = ws
    streamState.byteCount = 0
    streamState.md5checksum = md5.create()

    state.streams[streamType] = streamState
  },
  QUEUE_BLOB (state, blobInfo) {
    const streamState = state.streams[blobInfo.streamType]
    if (streamState) {
      streamState.uploadQueue[blobInfo.segmentId] = blobInfo.blob
    }
  },
  SET_STREAM_WS (state, { ws, streamType }) {
    const streamState = state.streams[streamType]
    streamState.ws = ws
  },
  SET_STREAM_WS_CONNECTED (state, streamType) {
    const streamState = state.streams[streamType]
    streamState.reconnecting = false
  },
  SET_STREAM_WS_RECONNECTING (state, streamType) {
    const streamState = state.streams[streamType]
    streamState.reconnecting = true
    streamState.streamInterrupted = true
  },
  ADD_RECEIPT_ID (state, receiptId) {
    // record the number of times we've tried to send the final blob
    if (!state.finalBlobReceiptIds[receiptId]) {
      state.finalBlobReceiptIds[receiptId] = {
        attempts: 0
      }
    } else {
      let attempts = state.finalBlobReceiptIds[receiptId].attempts
      state.finalBlobReceiptIds[receiptId] = {
        attempts: ++attempts
      }
    }
  },
  REMOVE_RECEIPT_ID (state, receiptId) {
    delete state.finalBlobReceiptIds[receiptId]
  },
  UPDATE_TOTAL_SIZE (state, size) {
    state.totalByteSize = state.totalByteSize + size
    state.remainingBufferSize = state.remainingBufferSize + size
  },
  REDUCE_REMAINING_BUFFER_SIZE (state, size) {
    state.remainingBufferSize = state.remainingBufferSize - size
  },
  END_STREAM (state, streamType) {
    const streamState = state.streams[streamType]
    streamState.stoppedRecording = true
  },
  SENT_FINAL_BLOB (state, streamType) {
    const streamState = state.streams[streamType]
    streamState.sentFinalBlob = true
  },
  REMOVE_STREAM (state, streamType) {
    const streamState = state.streams[streamType]
    if (streamState) {
      if (streamState.ws) {
        streamState.ws.disconnect()
      }
      delete state.streams[streamType]
    }
  },
  UPDATE_STREAM_TIME (state, { streamType, videoLength }) {
    const streamState = state.streams[streamType]
    if (streamState) {
      streamState.videoLength = videoLength
    }
  },
  UPDATE_RECORD_LENGTH (state) {
    if (!Object.keys(state.streams).length) return
    const streamLengths = []
    for (const id in state.streams) {
      streamLengths.push(state.streams[id].videoLength)
    }
    state.previousRecordTime = Math.max(...streamLengths)
  },
  RESET_UPLOAD_PERCENT (state) {
    state.totalByteSize = 0
    state.uploadPercent = 0
  },
  RESET_VIDEO_RECORDER (state) {
    Object.assign(state, getDefaultState())
  },
  RESERVE_STREAM_ID (state, streamId) {
    state.reservedStreamIds[streamId] = true
  },
  UNRESERVE_STREAM_ID (state, streamId) {
    delete state.reservedStreamIds[streamId]
  },
  STREAM_FULL_FILE_CHECKSUM (state, { streamType, checksum }) {
    state.streams[streamType].fullVideoChecksum = checksum
  }
}

function getVideoExtension (mimeType) {
  let fileExt = 'webm'
  if (mimeType.includes('mp4')) {
    fileExt = 'mp4'
  }
  return fileExt
}

function hasBufferedData (streamState, streamType) {
  // Websocket has data waiting to be put on the wire
  if (streamState.ws.ws.bufferedAmount !== 0) {
    return true
  }

  // We have not sent all the intended video blobs.
  if (streamState.queueIndex !== streamState.uploadQueue.length) {
    return true
  }

  // Not all data has been acked, this may mean we have to retransmit those blobs.
  // Therefore, those blobs can be considered queued.
  if (getBufferSize(streamType) > 0) {
    return true
  }

  return false
}

export default {
  state,
  getters,
  actions,
  mutations
}
