import viewModes from '@/residency/consts/resident-view-modes'
import log from '@/residency/utils/log'

const getDefaultState = () => {
  return {
    proctorGroups: [],
    expiredProctorGroups: [],
    viewMode: viewModes.RESIDENT
  }
}

const state = getDefaultState()

const getters = {
  isProctor: state => {
    return state.proctorGroups.length > 0
  },
  proctorGroups: state => {
    return state.proctorGroups
  },
  expiredProctorGroups: state => {
    return state.expiredProctorGroups
  },
  proctorViewMode: state => {
    return state.viewMode
  },
  isViewingAsProctor: state => {
    return state.viewMode === viewModes.PROCTOR
  }
}

const actions = {
  setProctorGroups: ({ commit }, proctorGroups) => {
    if (proctorGroups) {
      commit('SET_PROCTOR_GROUPS', proctorGroups.filter(group => group.isActive))
      commit('SET_EXPIRED_PROCTOR_GROUPS', proctorGroups.filter(group => !group.isActive))
    }
  },
  changeViewType: ({ commit }, newViewMode) => {
    if (!Object.values(viewModes).includes(newViewMode)) {
      log.error(`Unknown view type: ${newViewMode}`)
      return
    }
    commit('SET_VIEW_TYPE', newViewMode)
  },
  clearProctorState: ({ commit }) => {
    commit('CLEAR_PROCTOR_STATE')
  }
}

const mutations = {
  SET_PROCTOR_GROUPS (state, proctorGroups) {
    state.proctorGroups = proctorGroups
  },
  SET_EXPIRED_PROCTOR_GROUPS (state, expiredProctorGroups) {
    state.expiredProctorGroups = expiredProctorGroups
  },
  SET_VIEW_TYPE (state, viewMode) {
    state.viewMode = viewMode
  },
  CLEAR_PROCTOR_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
