import { simseiApi } from '@/residency/app-props'

const getDefaultState = () => {
  return {
    isSearchView: false,
    allActivities: []
  }
}

const state = getDefaultState()

const getters = {
  isSearchView (state) {
    return state.isSearchView
  },
  allActivities (state) {
    return state.allActivities
  }
}

const actions = {
  fetchAllActivities: async ({ commit }) => {
    const acitivityOptions = await simseiApi.get('activity/search/findAllByIsDeprecated', {
      params: {
        isDeprecated: false,
        projection: 'inlineActivityNameAndId'
      }
    })
    commit('SET_ALL_ACTIVITIES', acitivityOptions.data._embedded.activity)
  }
}

const mutations = {
  SET_SEARCH_VIEW (state, payload) {
    state.isSearchView = payload
  },
  SET_ALL_ACTIVITIES (state, payload) {
    state.allActivities = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
