import store from '@/residency/store/index'

const response = {
  '/user/topic/notification/new-asmt': (res) => {
    addNotifInfo(res)
  },
  '/user/topic/notification/shared-library': (res) => {
    addNotifInfo(res)
  },
  '/user/topic/notification/delete': (res) => {
    const notifId = JSON.parse(res.body).id
    store.commit('DELETE_NOTIFICATION', notifId)
  },
  '/user/topic/notification/event-delete': (res) => {
    addNotifInfo(res)
  }
}

function addNotifInfo (res) {
  const notif = JSON.parse(res.body)
  notif.creationDate = new Date()
  store.dispatch('addNewWSNotif', notif)
}

export default {
  response
}
