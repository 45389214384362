import { workshopApi } from '@/workshop/workshop-api.js'
import { workshopSpecialtyEnums, workshopBaseSpecialtyArray } from '@/workshop/consts/workshop-specialty-enums.js'

const getDefaultState = () => {
  return {
    userMgmtForm: {
      id: null,
      firstName: null,
      lastName: null,
      currentStatus: null,
      primaryPhone: null,
      cellPhone: null,
      email: null,
      hospitalName: null,
      hospitalAddressLine1: null,
      hospitalAddressLine2: null,
      hospitalZipCode: null,
      city: null,
      country: null,
      state: null,
      otherCountry: null,
      medicalLicenseNumber: null,
      medicalLicenseState: null,
      nationalProviderId: null,
      acceptingMeals: null,
      hasDietaryRestrictions: null,
      dietaryRestrictions: null,
      selectedSpecialties: [],
      selectedOtherSpecialty: null,
      degrees: [],
      certifications: [],
      credentials: [],
      sendRegistrationEmail: true,
      isInternal: false
    },
    isUserMgmtUserEnabled: false
  }
}

const state = getDefaultState()

const getters = {
  getUserMgmtForm (state) {
    return state.userMgmtForm
  },
  isUserMgmtFormEmpty (state) {
    const defaultUserMgmtForm = getDefaultState().userMgmtForm
    return Object.keys(defaultUserMgmtForm).every((key) => {
      if (state.userMgmtForm[key] instanceof Array) {
        return state.userMgmtForm[key].length === defaultUserMgmtForm[key].length
      } else {
        return state.userMgmtForm[key] === defaultUserMgmtForm[key] || state.userMgmtForm[key] === ''
      }
    })
  },
  getIsUserMgmtUserEnabled (state) {
    return state.isUserMgmtUserEnabled
  }
}

const actions = {
  postFacultyForm ({ state }) {
    const payload = getFacultyFormPayload(state.userMgmtForm)
    return workshopApi.post('/faculty/create-faculty', payload)
  },
  async getFacultyForm ({ commit }, facultyId) {
    const res = await workshopApi.get(`/workshop-user/${facultyId}`, {
      params: {
        projection: 'detailedFacultyData'
      }
    })

    const facultyInfo = res.data

    setParticipantInfo(facultyInfo, commit)
    setFacultyOnlyInfo(facultyInfo, commit)
  },
  updateFacultyForm ({ state }) {
    const payload = getFacultyFormPayload(state.userMgmtForm)
    return workshopApi.put('/faculty/update-faculty', payload)
  },
  async getAttendeeForm ({ commit }, attendeeId) {
    const res = await workshopApi.get(`/workshop-user/${attendeeId}`, {
      params: {
        projection: 'detailedAttendeeData'
      }
    })

    const attendeeInfo = res.data

    setParticipantInfo(attendeeInfo, commit)
    setAttendeeOnlyInfo(attendeeInfo, commit)
  },
  updateAttendeeForm ({ state }) {
    const payload = getAttendeeFormPayload(state.userMgmtForm)
    return workshopApi.put('/admin-attendee/update-attendee', payload)
  },
  async getFacultyAttendeeForm ({ commit }, userId) {
    const res = await workshopApi.get(`/workshop-user/${userId}`, {
      params: {
        projection: 'detailedFacultyAttendeeData'
      }
    })

    const facultyAttendeeInfo = res.data

    setParticipantInfo(facultyAttendeeInfo, commit)
    setAttendeeOnlyInfo(facultyAttendeeInfo, commit)
    setFacultyOnlyInfo(facultyAttendeeInfo, commit)
  },
  updateFacultyAttendeeForm ({ state }) {
    const payload = getFacultyAttendeeFormPayload(state.userMgmtForm)
    return workshopApi.put('/admin-participant/update-faculty-attendee', payload)
  }
}

const mutations = {
  SET_ID (state, id) {
    state.userMgmtForm.id = id
  },
  SET_FIRST_NAME (state, firstName) {
    state.userMgmtForm.firstName = firstName
  },
  SET_LAST_NAME (state, lastName) {
    state.userMgmtForm.lastName = lastName
  },
  SET_CURRENT_STATUS (state, currentStatus) {
    state.userMgmtForm.currentStatus = currentStatus
  },
  SET_PRIMARY_PHONE (state, primaryPhone) {
    state.userMgmtForm.primaryPhone = primaryPhone
  },
  SET_CELL_PHONE (state, cellPhone) {
    state.userMgmtForm.cellPhone = cellPhone
  },
  SET_EMAIL (state, email) {
    state.userMgmtForm.email = email
  },
  SET_HOSPITAL_NAME (state, hospitalName) {
    state.userMgmtForm.hospitalName = hospitalName
  },
  SET_HOSPITAL_ADDRESS_LINE_1 (state, hospitalAddressLine1) {
    state.userMgmtForm.hospitalAddressLine1 = hospitalAddressLine1
  },
  SET_HOSPITAL_ADDRESS_LINE_2 (state, hospitalAddressLine2) {
    state.userMgmtForm.hospitalAddressLine2 = hospitalAddressLine2
  },
  SET_HOSPITAL_ZIP_CODE (state, hospitalZipCode) {
    state.userMgmtForm.hospitalZipCode = hospitalZipCode
  },
  SET_CITY (state, city) {
    state.userMgmtForm.city = city
  },
  SET_COUNTRY (state, country) {
    state.userMgmtForm.country = country
  },
  SET_STATE (state, userState) {
    state.userMgmtForm.state = userState
  },
  SET_OTHER_COUNTRY (state, otherCountry) {
    state.userMgmtForm.otherCountry = otherCountry
  },
  SET_MEDICAL_LICENSE_NUMBER (state, medicalLicenseNumber) {
    state.userMgmtForm.medicalLicenseNumber = medicalLicenseNumber
  },
  SET_MEDICAL_LICENSE_STATE (state, medicalLicenseState) {
    state.userMgmtForm.medicalLicenseState = medicalLicenseState
  },
  SET_NATIONAL_PROVIDER_ID (state, nationalProviderId) {
    state.userMgmtForm.nationalProviderId = nationalProviderId
  },
  SET_SELECTED_SPECIALTIES (state, selectedSpecialties) {
    state.userMgmtForm.selectedSpecialties = selectedSpecialties
  },
  SET_OTHER_SPECIALTY (state, selectedOtherSpecialty) {
    state.userMgmtForm.selectedOtherSpecialty = selectedOtherSpecialty
  },
  SET_DEGREES (state, degrees) {
    state.userMgmtForm.degrees = degrees
  },
  SET_CERTIFICATIONS (state, certifications) {
    state.userMgmtForm.certifications = certifications
  },
  SET_CREDENTIALS (state, credentials) {
    state.userMgmtForm.credentials = credentials
  },
  SET_ACCEPTING_MEALS (state, acceptingMeals) {
    state.userMgmtForm.acceptingMeals = acceptingMeals
  },
  SET_HAS_DIETARY_RESTRICTIONS (state, hasDietaryRestrictions) {
    state.userMgmtForm.hasDietaryRestrictions = hasDietaryRestrictions
  },
  SET_DIETARY_RESTRICTIONS (state, dietaryRestrictions) {
    state.userMgmtForm.dietaryRestrictions = dietaryRestrictions
  },
  SET_SEND_REGISTRATION_EMAIL (state, sendRegistrationEmail) {
    state.userMgmtForm.sendRegistrationEmail = sendRegistrationEmail
  },
  SET_IS_ENABLED (state, isEnabled) {
    state.isUserMgmtUserEnabled = isEnabled
  },
  SET_IS_INTERNAL (state, isInternal) {
    state.userMgmtForm.isInternal = isInternal
  },
  RESET_USER_MGMT_FORM (state) {
    Object.assign(state, getDefaultState())
  }
}

function getFacultyFormPayload (facultyForm) {
  return {
    ...getParticipantFormPayload(facultyForm),
    degrees: facultyForm.degrees,
    certifications: facultyForm.certifications,
    credentials: facultyForm.credentials
  }
}

function getAttendeeFormPayload (attendeeForm) {
  return {
    ...getParticipantFormPayload(attendeeForm),
    currentStatus: attendeeForm.currentStatus,
    cellPhone: attendeeForm.cellPhone,
    hospitalAddressLine1: attendeeForm.hospitalAddressLine1,
    hospitalAddressLine2: attendeeForm.hospitalAddressLine2,
    hospitalZipCode: attendeeForm.hospitalZipCode,
    medicalLicenseNumber: attendeeForm.medicalLicenseNumber,
    medicalLicenseState: attendeeForm.medicalLicenseState,
    nationalProviderId: attendeeForm.nationalProviderId,
    acceptingMeals: attendeeForm.acceptingMeals,
    dietaryRestrictions: attendeeForm.hasDietaryRestrictions ? attendeeForm.dietaryRestrictions : ''
  }
}

function getParticipantFormPayload (participantForm) {
  return {
    id: participantForm.id,
    firstName: participantForm.firstName,
    lastName: participantForm.lastName,
    email: participantForm.email,
    phone: participantForm.primaryPhone,
    hospitalName: participantForm.hospitalName,
    city: participantForm.city,
    state: participantForm.state,
    country: participantForm.country === 'Other' ? participantForm.otherCountry : participantForm.country,
    specialty: getSpecialtyPayload(participantForm.selectedSpecialties, participantForm.selectedOtherSpecialty),
    sendRegistrationEmail: participantForm.sendRegistrationEmail,
    isInternal: participantForm.isInternal
  }
}

function getFacultyAttendeeFormPayload (facultyAttendeeForm) {
  return {
    ...getAttendeeFormPayload(facultyAttendeeForm),
    degrees: facultyAttendeeForm.degrees,
    certifications: facultyAttendeeForm.certifications,
    credentials: facultyAttendeeForm.credentials
  }
}

function setParticipantInfo (participantInfo, commit) {
  commit('SET_ID', participantInfo.id)
  commit('SET_FIRST_NAME', participantInfo.firstName)
  commit('SET_LAST_NAME', participantInfo.lastName)
  commit('SET_PRIMARY_PHONE', participantInfo.primaryPhone)
  commit('SET_EMAIL', participantInfo.email)
  commit('SET_HOSPITAL_NAME', participantInfo.hospitalName)
  commit('SET_CITY', participantInfo.city)
  commit('SET_IS_INTERNAL', participantInfo.isInternal)
  commit('SET_IS_ENABLED', participantInfo.enabled)

  if (participantInfo.country === 'USA') {
    commit('SET_COUNTRY', participantInfo.country)
    commit('SET_STATE', participantInfo.state)
  } else {
    commit('SET_COUNTRY', 'Other')
    commit('SET_OTHER_COUNTRY', participantInfo.country)
  }

  let specialties = participantInfo.specialties.map((specialtyObj) => specialtyObj.specialty)

  const otherSpecialty = getOtherSpecialty(specialties)
  if (otherSpecialty) {
    commit('SET_OTHER_SPECIALTY', otherSpecialty)
    specialties = specialties.filter((specialty) => specialty !== otherSpecialty)
    specialties.push(workshopSpecialtyEnums.OTHER)
  }

  commit('SET_SELECTED_SPECIALTIES', specialties)
}

function setFacultyOnlyInfo (facultyInfo, commit) {
  const degrees = facultyInfo.degrees.map((degreeObj) => degreeObj.degree)
  commit('SET_DEGREES', degrees)

  const certifications = facultyInfo.certifications.map((certificationObj) => certificationObj.certification)
  commit('SET_CERTIFICATIONS', certifications)

  const credentials = facultyInfo.credentials.map((credentialObj) => credentialObj.credential)
  commit('SET_CREDENTIALS', credentials)
}

function setAttendeeOnlyInfo (attendeeInfo, commit) {
  commit('SET_CURRENT_STATUS', attendeeInfo.currentStatus)
  commit('SET_CELL_PHONE', attendeeInfo.cellPhone)
  commit('SET_HOSPITAL_ADDRESS_LINE_1', attendeeInfo.hospitalAddressLine1)
  commit('SET_HOSPITAL_ADDRESS_LINE_2', attendeeInfo.hospitalAddressLine2)
  commit('SET_HOSPITAL_ZIP_CODE', attendeeInfo.hospitalZipCode)
  commit('SET_MEDICAL_LICENSE_NUMBER', attendeeInfo.medicalLicenseNumber)
  commit('SET_MEDICAL_LICENSE_STATE', attendeeInfo.medicalLicenseState)
  commit('SET_NATIONAL_PROVIDER_ID', attendeeInfo.nationalProviderId)
  commit('SET_ACCEPTING_MEALS', attendeeInfo.acceptingMeals)
  commit('SET_DIETARY_RESTRICTIONS', attendeeInfo.dietaryRestrictions)
  if (attendeeInfo.dietaryRestrictions) {
    commit('SET_DIETARY_RESTRICTIONS', attendeeInfo.dietaryRestrictions)
    commit('SET_HAS_DIETARY_RESTRICTIONS', true)
  } else {
    commit('SET_HAS_DIETARY_RESTRICTIONS', false)
  }
}

function getSpecialtyPayload (selectedSpecialties, selectedOtherSpecialty) {
  const hasOtherSpecialty = selectedSpecialties.includes(workshopSpecialtyEnums.OTHER)
  if (hasOtherSpecialty) {
    const specialityWithoutOther = selectedSpecialties.filter((specialty) => specialty !== workshopSpecialtyEnums.OTHER)
    specialityWithoutOther.push(selectedOtherSpecialty)
    return specialityWithoutOther
  }

  return selectedSpecialties
}

function getOtherSpecialty (specialties) {
  const otherSpecialty = specialties.find((specialty) => !workshopBaseSpecialtyArray.includes(specialty))
  return otherSpecialty
}

export default {
  state,
  getters,
  actions,
  mutations
}
