import { simseiApi } from '@/residency/app-props'
import { eventTypes } from '@/residency/views/schedule/schedule-enums'
import { parseDateAndTimeForProgram, parseLocaleDate } from '@/residency/utils/residency-date-util.js'
import { convertToUtc } from '@/utils/date-util'
import { binarySearch } from '@/residency/utils/store-util'
import store from '@/residency/store'
import userRoles from '@/residency/consts/user-roles'

const getEventFormState = () => {
  return {
    id: null,
    title: null,
    location: null,
    startDate: null,
    startTime: null,
    endTime: null,
    notes: '',
    attachments: [],
    facultyAttendees: [],
    proctorAttendees: [],
    simAttendees: [],
    ftmAttendees: [],
    userGroupAttendees: new Set(),
    residentAttendees: new Set(),
    activities: [],
    programId: null
  }
}

const defaultState = () => {
  return {
    locations: [],
    events: [],
    futureEvents: [],
    masterSchedulePastEvents: [],
    isEventsRequestSuccessful: true,
    eventForm: {},
    selectedEvent: null,
    fetchedDataProgramId: null,
    isEditingEvent: false,
    isDuplicatingEvent: false
  }
}

const state = defaultState()

const getters = {
  scheduleLocations (state, getters, rootState) {
    return state.locations
  },
  scheduleEvents (state, getters, rootState) {
    return state.events
  },
  futureEvents (state) {
    return state.futureEvents
  },
  isEventsRequestSuccessful (state, getters) {
    return state.isEventsRequestSuccessful
  },
  eventForm (state) {
    return state.eventForm
  },
  selectedEvent (state) {
    return state.selectedEvent
  },
  selectedActivities (state) {
    return state.eventForm.activities
  },
  isEventFormEmpty (state) {
    const eventForm = state.eventForm

    for (const key of Object.keys(eventForm)) {
      // skip the eventType because it is always filled
      if ([key][0] === 'eventType') continue

      if (eventForm[key] instanceof Set && eventForm[key].size > 0) return false

      if (eventForm[key] !== null && eventForm[key] !== '' &&
      eventForm[key] !== undefined && eventForm[key].length) return false
    }

    return true
  },
  isDuplicatingEvent (state) {
    return state.isDuplicatingEvent
  },
  isEditingEvent (state) {
    return state.isEditingEvent
  },
  masterSchedulePastEvents (state) {
    return state.masterSchedulePastEvents
  }
}

const actions = {
  resetEvents ({ commit }) {
    commit('SET_SCHEDULE_EVENTS', [])
    commit('SET_FUTURE_EVENTS', [])
  },
  setSelectedPastEventById ({ rootGetters, commit }, eventId) {
    const selectedEvent = rootGetters.masterSchedulePastEvents.find(event => event.id === eventId)
    if (selectedEvent === undefined) {
      throw new Error(`Event [${eventId}] not found in masterSchedulePastEvents`)
    }
    commit('SET_SELECTED_EVENT', selectedEvent)
  },
  setSelectedEventById ({ rootGetters, commit }, eventId) {
    const selectedEvent = rootGetters.scheduleEvents.find(event => event.id === eventId)
    if (selectedEvent === undefined) {
      throw new Error(`Event [${eventId}] not found in scheduleEvents`)
    }
    commit('SET_SELECTED_EVENT', selectedEvent)
  },
  fetchScheduleEvents: async ({ commit, dispatch, rootGetters }) => {
    if (rootGetters.isAdministrativeRole) return

    let requestPath = ''

    if (rootGetters.isViewingAsProctor) {
      requestPath = '/event/proctor'
    } else if (rootGetters.isResident) {
      requestPath = '/event/resident'
    } else {
      requestPath = '/event/faculty'
    }

    try {
      const response = await simseiApi.get(requestPath)
      const events = response.data
      dispatch('updateScheduleEvents', events)
      commit('SET_EVENTS_REQUEST_SUCCEEDED', true)
    } catch (error) {
      commit('SET_EVENTS_REQUEST_SUCCEEDED', false)
      throw error
    }
  },
  fetchAllPastEvent: async ({ commit }) => {
    const response = await simseiApi.get('/event/all-past-events')
    const events = response.data
    commit('SET_MASTER_SCHEDULE_PAST_EVENTS', events)
  },
  // Loads a mix of all future event and events from a bit over a month in the past. Returns the startDate of an event
  // that is furthest in the past. If programId parameter is supplied, only events for that specific program are loaded.
  // otherwise, events for all programs are loaded.
  fetchScheduleEventsAroundToday: async ({ dispatch, commit }, programId) => {
    // fetch past events for the next 38 days (current month + 7 extra days).
    // the + 7 days is to account for the cells that belong to the previous month in the month view
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const dateRanges = getDateRange(now.getTime(), 38)
    const pastEventsRequestUrl = programId
      ? `/event/program/${programId}/events-in-date-range?${dateRanges.queryString}`
      : `/event/events-in-date-range?${dateRanges.queryString}`
    const res = await simseiApi.get(pastEventsRequestUrl)
    const pastEvents = res.data

    const futureEventsRequestUrl = programId
      ? `/event/program/${programId}/future`
      : '/event/all-future-events'
    const response = await simseiApi.get(futureEventsRequestUrl)
    const events = response.data

    commit('SET_FUTURE_EVENTS', events)

    const allEvents = pastEvents.concat(events)
    commit('SET_SCHEDULE_EVENTS', allEvents)
  },
  // Loads events between a specified date range. If programId parameter is supplied, then only events for that specific
  // program are loaded. Otherwise, events for all programs are loaded.
  fetchScheduleEventsDaysBefore: async ({ commit, dispatch }, { programId, now, daysBefore }) => {
    const dateRanges = getDateRange(now, daysBefore)
    const requestUrl = programId
      ? `/event/program/${programId}/events-in-date-range?${dateRanges.queryString}`
      : `/event/events-in-date-range?${dateRanges.queryString}`
    const res = await simseiApi.get(requestUrl)

    dispatch('prependEventsLessThanMinDate', { events: res.data })
  },
  prependEventsLessThanMinDate: ({ commit, state }, { events }) => {
    const oldestEvent = state.events[0]
    const minDateSeen = state.events.length > 0
      ? new Date(oldestEvent.startDate).getTime()
      : Number.MAX_SAFE_INTEGER
    // only add events that have not been seen (older than those already loaded)
    const filteredEvents = events.filter(event => {
      const start = new Date(event.startDate).getTime()
      return start < minDateSeen
    })

    commit('SET_SCHEDULE_EVENTS', filteredEvents.concat(state.events))
  },
  fetchFullEventDetails: async ({ commit }, eventId) => {
    const response = await simseiApi.get(`/event/details/${eventId}`)
    const event = response.data
    return event
  },
  fetchEventLocations: async ({ commit }, programId) => {
    const resp = await simseiApi.get(`/event/program/${programId}/locations`)
    commit('SET_LOCATIONS', resp.data)
  },
  updateScheduleEvents: async ({ commit }, events) => {
    commit('SET_SCHEDULE_EVENTS', events)
  },
  submitEventForm: async ({ state, commit, dispatch }) => {
    const parsedEvent = parseEvent(state.eventForm)

    const form = new FormData()
    form.append('event', new Blob([JSON.stringify(parsedEvent)], { type: 'application/json' }))
    state.eventForm.attachments.forEach(file => {
      form.append('attachments', file)
    })

    const response = await simseiApi.post('/event', form, {
      headers: { 'Content-Type': 'multipart/form-data; boundary=WebKitFormBoundaryf50f01e88sVs' }
    })

    commit('ADD_SCHEDULE_EVENT', response.data)
    commit('UPDATE_LOCATIONS', response.data.location)
    return response
  },
  updateEvent: async ({ commit, dispatch }) => {
    const parsedEvent = parseEvent(state.eventForm)

    const form = new FormData()
    form.append('event', new Blob([JSON.stringify(parsedEvent)], { type: 'application/json' }))
    state.eventForm.attachments.forEach(file => {
      form.append('attachments', file)
    })

    const response = await simseiApi.patch('/event', form, {
      headers: { 'Content-Type': 'multipart/form-data; boundary=WebKitFormBoundaryf50f01e88sVs' }
    })

    commit('ADD_SCHEDULE_EVENT', response.data)
    commit('UPDATE_LOCATIONS', response.data.location)
    return response
  },
  // Ensure that resident events aren't added to the dashboard while viewing as a proctor, and proctor
  // events aren't added to the dashboard while viewing as a resident. Also ensures that faculty events
  // are passed through, since they don't have the isViewingAsProctor flag.
  addScheduleEventFromWebsocket: ({ commit, rootGetters }, event) => {
    const proctor = event.proctorAttendance.find(proctor => proctor.id === rootGetters.me.id)
    // If the user is proctoring this event and the user is in proctor mode, add the event.
    // If the user is not proctoring the event and the user is not in proctor mode, add the event.
    // If the user is faculty they will be in the proctor list, so this condition will be met.
    if (!!proctor === (rootGetters.isViewingAsProctor || rootGetters.isFaculty)) {
      commit('ADD_SCHEDULE_EVENT', event)
      commit('UPDATE_LOCATIONS', event.location)
    }
  },
  deleteProgramEvent: async ({ commit }, eventId) => {
    await simseiApi.delete('/event/' + eventId)
    commit('DELETE_SCHEDULE_EVENT', eventId)
  },
  initializeEventForm: ({ commit }, type) => {
    commit('RESET_EVENT_FORM')
    commit('SET_EVENT_TYPE', type)
  },
  clearEvents: async ({ commit }) => {
    commit('CLEAR_EVENTS')
  },
  updateSelectedActivites: ({ state, commit }, activity) => {
    if (state.eventForm.activities.some((selectActivity) => selectActivity.id === activity.id)) {
      commit('REMOVE_SELECTED_ACTIVITY', activity)
    } else {
      commit('ADD_SELECTED_ACTIVITY', activity)
    }
  },
  copySelectedEventToEventForm: async ({ commit, state, dispatch }, isEditing) => {
    const selectedEvent = state.selectedEvent
    await dispatch('fetchEventCreateModalData', selectedEvent.program.id)
    dispatch('initializeEventForm', selectedEvent.type.eventType)

    // Set Program Id
    commit('SET_EVENT_FORM_PROGRAM_ID', selectedEvent.program.id)

    // Set Time and Event Id if Editing Event
    if (isEditing) {
      const startDateObj = parseDateAndTimeForProgram(selectedEvent.startDate)
      const endDateObj = parseDateAndTimeForProgram(selectedEvent.endDate)
      commit('SET_EVENT_FORM_START_DATE', startDateObj.date)
      commit('SET_EVENT_FORM_START_TIME', startDateObj.time)
      commit('SET_EVENT_FORM_END_TIME', endDateObj.time)
      commit('SET_EVENT_ID', selectedEvent.id)
    }

    // Set Title
    commit('SET_FORM_TITLE', selectedEvent.title)

    // Set Location
    commit('SET_EVENT_FORM_LOCATION', selectedEvent.location.name)

    // Set User Groups and Users
    const userGroupIdSet = new Set(selectedEvent.groupAttendance.map(userGroup => userGroup.id))
    const userIdSet = new Set(selectedEvent.residentAttendance.map(user => user.id))
    store.getters.adminUnexpiredGroups.forEach(userGroup => {
      if (userGroupIdSet.has(userGroup.id)) {
        commit('ADD_EVENT_FORM_USER_GROUP_ATTENDEE', userGroup)
      }

      if (userGroup.users) {
        userGroup.users.forEach(user => {
          if (userIdSet.has(user.id)) {
            commit('ADD_EVENT_FORM_RESIDENT_ATTENDEE', user)
            userIdSet.delete(user.id)
          }
        })
      }
    })

    // Set Proctors and Faculty
    const proctorIdSet = new Set(selectedEvent.proctorAttendance.map(proctor => proctor.id))
    store.getters.adminProgramFaculty.forEach(eventProctor => {
      if (proctorIdSet.has(eventProctor.id) && (
        eventProctor.role === userRoles.FACULTY ||
        eventProctor.role === userRoles.PROG_DIRECTOR
      )) {
        commit('ADD_EVENT_FORM_FACULTY_ATTENDEES', eventProctor)
      }
    })
    store.getters.adminProgramProctors.forEach(eventProctor => {
      if (proctorIdSet.has(eventProctor.id)) {
        commit('ADD_EVENT_FORM_PROCTOR_ATTENDEE', eventProctor)
      }
    })

    // Set Internal Users
    const interalIdSet = new Set(selectedEvent.internalAttendance.map(interal => interal.id))
    store.getters.getOfficialInternalUsers.forEach(internal => {
      if (interalIdSet.has(internal.id) && internal.role === userRoles.SIMSEI_IMPLEMENTATION_MANAGER) {
        commit('SET_EVENT_FORM_SIM_ATTENDEE', internal)
      }

      if (interalIdSet.has(internal.id) && internal.role === userRoles.FIELD_TEAM_MEMBER) {
        commit('ADD_EVENT_FORM_FTM_ATTENDEE', internal)
      }
    })

    // Set Notes if exists
    if (selectedEvent.notes) {
      commit('SET_EVENT_FORM_NOTES', selectedEvent.notes)
    }

    // Set Activities
    if (selectedEvent.type.eventType === eventTypes.LAB) {
      const activityIdMap = new Map(selectedEvent.activities.map(activity =>
        [activity.activity.id, activity.activityType]
      ))
      store.getters.nonDeprecatedActivities.forEach(activity => {
        if (activityIdMap.has(activity.id)) {
          activity.activityType = activityIdMap.get(activity.id)
          commit('ADD_SELECTED_ACTIVITY', activity)
        }
      })
    }

    // Set Attachments
    commit('SET_EVENT_FORM_ATTACHMENTS', selectedEvent.attachments)
  },
  fetchEventCreateModalData: async ({ state, dispatch, commit, rootGetters }, programId) => {
    if (rootGetters.areUsersFetched && programId === state.fetchedDataProgramId) return
    await Promise.all([
      dispatch('getProgramDetails', programId),
      dispatch('fetchProgramFaculty', programId),
      dispatch('fetchProgramProctors', programId),
      dispatch('fetchInternalUsers'),
      dispatch('fetchEventLocations', programId),
      dispatch('fetchNonDeprecatedActivities'),
      dispatch('fetchUserGroupUsers', programId)
    ])
    commit('SET_FETCHED_DATA_PROGRAM_ID', programId)
  }
}

const mutations = {
  SET_EVENT_TYPE (state, type) {
    state.eventForm.eventType = type
  },
  SET_FORM_TITLE (state, title) {
    state.eventForm.title = title
  },
  SET_EVENT_FORM_LOCATION (state, location) {
    state.eventForm.location = location
  },
  SET_EVENT_FORM_START_DATE (state, startDate) {
    state.eventForm.startDate = startDate
  },
  SET_EVENT_FORM_START_TIME (state, startTime) {
    state.eventForm.startTime = startTime
  },
  SET_EVENT_FORM_END_TIME (state, endTime) {
    state.eventForm.endTime = endTime
  },
  SET_EVENT_FORM_NOTES (state, notes) {
    state.eventForm.notes = notes
  },
  SET_EVENT_FORM_ATTACHMENTS (state, attachments) {
    state.eventForm.attachments = attachments
  },
  SET_EVENT_FORM_FACULTY_ATTENDEES (state, facultyAttendees) {
    state.eventForm.facultyAttendees = facultyAttendees
  },
  ADD_EVENT_FORM_FACULTY_ATTENDEES (state, facultyAttendee) {
    state.eventForm.facultyAttendees.push(facultyAttendee)
  },
  SET_EVENT_FORM_PROCTOR_ATTENDEES (state, proctorAttendees) {
    state.eventForm.proctorAttendees = proctorAttendees
  },
  ADD_EVENT_FORM_PROCTOR_ATTENDEE (state, proctorAttendee) {
    state.eventForm.proctorAttendees.push(proctorAttendee)
  },
  SET_EVENT_FORM_SIM_ATTENDEES (state, simAttendees) {
    state.eventForm.simAttendees = simAttendees
  },
  SET_EVENT_FORM_SIM_ATTENDEE (state, simAttendee) {
    state.eventForm.simAttendees.push(simAttendee)
  },
  SET_EVENT_FORM_USER_GROUP_ATTENDEES (state, userGroupAttendees) {
    state.eventForm.userGroupAttendees = userGroupAttendees
  },
  ADD_EVENT_FORM_USER_GROUP_ATTENDEE (state, userGroupAttendee) {
    state.eventForm.userGroupAttendees.add(userGroupAttendee)
  },
  SET_EVENT_FORM_RESIDENT_ATTENDEES (state, residentAttendees) {
    state.eventForm.residentAttendees = residentAttendees
  },
  ADD_EVENT_FORM_RESIDENT_ATTENDEE (state, residentAttendee) {
    state.eventForm.residentAttendees.add(residentAttendee)
  },
  SET_EVENT_FORM_FTM_ATTENDEES (state, ftmAttendees) {
    state.eventForm.ftmAttendees = ftmAttendees
  },
  ADD_EVENT_FORM_FTM_ATTENDEE (state, ftmAttendee) {
    state.eventForm.ftmAttendees.push(ftmAttendee)
  },
  UPDATE_LOCATIONS (state, location) {
    if (!state.locations.find(loc => loc.name === location.name)) {
      state.locations.push(location)
      state.locations.sort()
    }
  },
  SET_LOCATIONS (state, locations) {
    state.locations = locations
  },
  SET_SCHEDULE_EVENTS (state, events) {
    events.forEach(event => {
      event.startDate = convertToUtc(event.startDate)
      event.endDate = convertToUtc(event.endDate)
    })
    state.events = events
  },
  SET_FUTURE_EVENTS (state, events) {
    events.forEach(event => {
      event.startDate = convertToUtc(event.startDate)
      event.endDate = convertToUtc(event.endDate)
    })
    state.futureEvents = events
  },
  SET_MASTER_SCHEDULE_PAST_EVENTS (state, events) {
    events.forEach(event => {
      event.startDate = convertToUtc(event.startDate)
      event.endDate = convertToUtc(event.endDate)
    })
    state.masterSchedulePastEvents = events
  },
  ADD_SCHEDULE_EVENT (state, event) {
    event.startDate = convertToUtc(event.startDate)
    event.endDate = convertToUtc(event.endDate)

    let eventIndex = state.events.findIndex(stateEvent => stateEvent.id === event.id)
    if (eventIndex >= 0) state.events.splice(eventIndex, 1)

    eventIndex = binarySearchEvent(state.events, event)
    state.events.splice(eventIndex, 0, event)

    let futureIndex = state.futureEvents.findIndex(stateEvent => stateEvent.id === event.id)
    if (futureIndex >= 0) state.futureEvents.splice(futureIndex, 1)

    futureIndex = binarySearchEvent(state.futureEvents, event)
    state.futureEvents.splice(futureIndex, 0, event)
  },
  DELETE_SCHEDULE_EVENT (state, eventId) {
    const eventIndex = state.events.findIndex(event => event.id === eventId)
    if (eventIndex >= 0) state.events.splice(eventIndex, 1)

    const futureIndex = state.futureEvents.findIndex(event => event.id === eventId)
    if (futureIndex >= 0) state.futureEvents.splice(futureIndex, 1)

    const pastIndex = state.masterSchedulePastEvents.findIndex(event => event.id === eventId)
    if (pastIndex >= 0) state.masterSchedulePastEvents.splice(pastIndex, 1)
  },
  RESET_EVENT_FORM (state) {
    Object.assign(state.eventForm, getEventFormState())
    store.dispatch('resetNonDeprecatedActivities')
  },
  CLEAR_EVENTS (state) {
    Object.assign(state, defaultState())
  },
  SET_EVENTS_REQUEST_SUCCEEDED (state, success) {
    state.isEventsRequestSuccessful = success
  },
  SET_SELECTED_EVENT (state, selectedEvent) {
    state.selectedEvent = selectedEvent
  },
  CLEAR_SELECTED_EVENT (state) {
    state.selectedEvent = null
  },
  REMOVE_SELECTED_ACTIVITY (state, activity) {
    state.eventForm.activities.splice(state.eventForm.activities.indexOf(activity), 1)
    activity.activityType = null
  },
  ADD_SELECTED_ACTIVITY (state, activity) {
    state.eventForm.activities.push(activity)
  },
  UPDATE_SELECTED_ACTIVITY_TYPE (state, { activity, activityType }) {
    state.eventForm.activities[state.eventForm.activities.indexOf(activity)].activityType = activityType
  },
  SET_FETCHED_DATA_PROGRAM_ID (state, programId) {
    state.fetchedDataProgramId = programId
  },
  SET_IS_EDITING_EVENT (state, isEditing) {
    state.isEditingEvent = isEditing
  },
  SET_IS_DUPLICATING_EVENT (state, isDuplicating) {
    state.isDuplicatingEvent = isDuplicating
  },
  SET_EVENT_ID (state, eventId) {
    state.eventForm.id = eventId
  },
  SET_EVENT_FORM_PROGRAM_ID (state, programId) {
    state.eventForm.programId = programId
  }
}

function binarySearchEvent (sortedEvents, event, start, end) {
  return binarySearch(sortedEvents, event, (event1, event2) => event1.startDate - event2.startDate)
}

function parseEventLocation (event) {
  const location = state.locations.find(location => location.name === event.location) || { name: event.location }
  return {
    ...location,
    name: location.name.trim()
  }
}

function parseEvent (event) {
  return {
    id: event.id,
    title: event.title,
    startDate: parseLocaleDate(event.startDate, event.startTime),
    endDate: parseLocaleDate(event.startDate, event.endTime),
    location: parseEventLocation(event),
    notes: event.notes.trim(),
    type: { eventType: event.eventType },
    program: { id: event.programId || store.getters.adminSelectedProgram.id },
    groupAttendance: [...event.userGroupAttendees],
    residentAttendance: [...event.residentAttendees],
    proctorAttendance: [...event.facultyAttendees, ...event.proctorAttendees],
    internalAttendance: [...event.simAttendees, ...event.ftmAttendees],
    activities: event.eventType === eventTypes.LAB ? formatActivity(event.activities) : [],
    attachments: [...event.attachments.filter(attachment => attachment.filePath)]
  }
}

function formatActivity (activities) {
  const formattedActivities = []

  activities.forEach((activity) => {
    formattedActivities.push({ activity: { id: activity.id }, activityType: activity.activityType })
  })

  return formattedActivities
}

/**
 * Calculates the dates from a given start point (now) to some amount of days in the past. For example, this method can
 * be used to compute what date is 70 days in the past from Jan 1st 2020.
 *
 * @param now - When to compute dates in the past relative to ("now" is exclusive, but "now - daysBefore" is inclusive)
 * @param daysBefore - How many days in the past the range should include
 * @returns {{endDate: string, queryString: string, startDate: string}} - Returns an object with information about the
 * date range. The object also contains a query string to be used with endpoints that load events over a date range
 */
function getDateRange (now, daysBefore) {
  const startDate = new Date(now - daysBefore * 24 * 60 * 60 * 1000).toISOString().split('T')[0].concat('T00:00:00')
  const endDate = new Date(now).toISOString().split('T')[0].concat('T00:00:00')
  // date strings of format yyyy-MM-ddThh:mm:ss (e.g. 2042-05-09T13:10:00)
  return {
    startDate,
    endDate,
    queryString: `start=${startDate}&end=${endDate}`
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
