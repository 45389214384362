import { simseiApi } from '@/residency/app-props'
import { videoTypeEnums } from '@/residency/views/video/video-enums'

const defaultSharedVideosState = () => {
  return {
    allSharedVideos: [],
    sentByFaculty: [],
    savedVideos: [],
    currentSharedLibraryRequest: null
  }
}

const state = defaultSharedVideosState()

const getters = {
  allSharedVideos: state => state.allSharedVideos,
  sharedVideos: state => state.sharedVideos,
  sharedSimulationVideos: state => {
    return state.allSharedVideos.filter(video => {
      return videoTypeEnums[video.videoType] === videoTypeEnums.SIMULATION
    })
  },
  sharedSurgicalVideos: state => {
    return state.allSharedVideos.filter(video => {
      return videoTypeEnums[video.videoType] === videoTypeEnums.SURGICAL
    })
  },
  sharedOtherVideos: state => {
    return state.allSharedVideos.filter(video => {
      return videoTypeEnums[video.videoType] === videoTypeEnums.OTHER
    })
  },
  mySharedVideos: state => {
    return state.allSharedVideos.filter(video => video.isMyVideo)
  },
  savedVideos: state => state.savedVideos,
  videosSentByFaculty: state => state.sentByFaculty,
  sharedLibraryRequestInspectedByResident: state => state.currentSharedLibraryRequest
}

const actions = {
  addToSharedVideoLibrary: async ({ commit }, payload) => {
    await simseiApi.post('/shared-library', payload)
  },
  fetchSavedVideos: async ({ dispatch, commit, rootGetters }) => {
    await dispatch('fetchSentByFaculty')

    const filteredVideos = rootGetters.allSharedVideos.filter(video => {
      return video.isFavorited
    })
    commit('SET_SAVED_VIDEOS', filteredVideos)

    return filteredVideos
  },
  fetchAllSharedVideos: async ({ commit, rootGetters }) => {
    if (rootGetters.allSharedVideos.length > 0) {
      return rootGetters.allSharedVideos
    } else {
      const res = await simseiApi.get('/shared-library/videos/all')
      commit('SET_ALL_SHARED_VIDEOS', res.data)
      return res.data
    }
  },
  fetchSentByFaculty: async ({ commit, rootGetters }) => {
    if (rootGetters.videosSentByFaculty.length > 0) {
      return rootGetters.videosSentByFaculty
    } else {
      const res = await simseiApi.get('/shared-library/videos/recommended-shared')
      commit('SET_SENT_BY_FACULTY_VIDEOS', res.data)
      return res.data
    }
  },
  checkForSharedLibraryRequest: async ({ commit }, asmtId) => {
    const res = await simseiApi.get('/shared-library-request/' + asmtId)
    commit('SET_SHARED_REQUEST_INSPECTED_BY_RESIDENT', res.data)
  },
  acceptSharedLibraryRequest: async ({ commit }, requestId) => {
    await simseiApi.patch('/shared-library-request/accept/' + requestId)
  },
  saveVideo: async ({ commit, rootGetters }, sharedVideoId) => {
    const favoritedVideo = await simseiApi.post('/favorite-video/' + sharedVideoId)
    commit('SAVE_VIDEO', { sharedVideoId, favoriteId: favoritedVideo.data.id })
    return favoritedVideo.data.id
  },
  unsaveVideo: async ({ commit, rootGetters }, favoriteId) => {
    await simseiApi.delete('/favorite-video/' + favoriteId)
    commit('UNSAVE_VIDEO', favoriteId)
  },
  removeVideoFromSharedLibrary: async ({ commit, rootGetters }, sharedVideoId) => {
    await simseiApi.delete('/shared-library/' + sharedVideoId)

    const indexToRemove = rootGetters.allSharedVideos.findIndex(video => video.sharedVideoId === sharedVideoId)
    rootGetters.allSharedVideos.splice(indexToRemove, 1)
  },
  clearSharedVideoState: ({ commit }) => {
    commit('CLEAR_SHARED_VIDEO_STATE')
  }
}

const mutations = {
  SET_ALL_SHARED_VIDEOS (state, sharedVideos) {
    state.allSharedVideos = sharedVideos
  },
  SET_SIMULATION_VIDEOS (state, sharedVideos) {
    state.sharedVideos[videoTypeEnums.SIMULATION] = sharedVideos
  },
  SET_SURGICAL_VIDEOS (state, sharedVideos) {
    state.sharedVideos[videoTypeEnums.SURGICAL] = sharedVideos
  },
  SET_OTHER_VIDEOS (state, sharedVideos) {
    state.sharedVideos[videoTypeEnums.OTHER] = sharedVideos
  },
  SET_SAVED_VIDEOS (state, savedVideos) {
    state.savedVideos = savedVideos
  },
  SET_SENT_BY_FACULTY_VIDEOS (state, sentByFacultyVideos) {
    state.sentByFaculty = sentByFacultyVideos
  },
  SET_SHARED_REQUEST_INSPECTED_BY_RESIDENT (state, sharedLibraryRequest) {
    state.currentSharedLibraryRequest = sharedLibraryRequest
  },
  SAVE_VIDEO (state, { sharedVideoId, favoriteId }) {
    const video = state.allSharedVideos.find(video => video.sharedVideoId === sharedVideoId)
    const sentByFaculty = state.sentByFaculty.find(video => video.sharedVideoId === sharedVideoId)

    if (video) {
      video.favoriteId = favoriteId
      video.isFavorited = true
      state.savedVideos.push(video)
    }

    if (sentByFaculty) {
      sentByFaculty.favoriteId = favoriteId
      sentByFaculty.isFavorited = true
    }
  },
  UNSAVE_VIDEO (state, favoriteId) {
    const unsavedVideo = state.allSharedVideos.find(video => video.favoriteId === favoriteId)
    const sentByFaculty = state.sentByFaculty.find(video => video.videoId === unsavedVideo.videoId)

    if (sentByFaculty) {
      sentByFaculty.favoriteId = null
      sentByFaculty.isFavorited = false
    }

    if (unsavedVideo) {
      unsavedVideo.favoriteId = null
      unsavedVideo.isFavorited = false
      state.savedVideos = state.allSharedVideos.filter(video => video.isFavorited)
    }
  },
  CLEAR_SHARED_VIDEO_STATE (state) {
    Object.assign(state, defaultSharedVideosState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
