import { simseiApi } from '@/residency/app-props'
import userRoles from '@/residency/consts/user-roles'

const getDefaultState = () => {
  return {
    // Includes users who are neither residents nor faculty, as well as test administrative users.
    internalUsers: [],
    // Includes the official TMs who manage programs with customers. This list does not include the test adminstrative users.
    officialInternalUsers: [],
    userToEdit: null
  }
}

const state = getDefaultState()

const getters = {
  isResident (state, getters, rootState) {
    return rootState.auth.me.role === userRoles.RESIDENT
  },
  isActiveResident (state, getters) {
    return getters.me.role === userRoles.RESIDENT &&
      getters.me.isActive &&
      !getters.isViewingAsProctor
  },
  isFaculty (state, getters, rootState) {
    return rootState.auth.me.role === userRoles.FACULTY
  },
  isProgramDirector (state, getters, rootState) {
    return rootState.auth.me.role === userRoles.PROG_DIRECTOR
  },
  isAdmin (state, getters, rootState) {
    return rootState.auth.me.role === userRoles.SIMSEI_ADMIN
  },
  isSystemCoordinator (state, getters, rootState) {
    return rootState.auth.me.role === userRoles.SYS_COORDINATOR
  },
  getInternalUsers (state) {
    return state.internalUsers
  },
  getOfficialInternalUsers (state) {
    return state.officialInternalUsers
  },
  getInternalUserToEdit (state) {
    return state.userToEdit
  },
  isAdministrativeRole (state, getters, rootState) {
    const userRole = rootState.auth.me.role
    return userRole === userRoles.SIMSEI_ADMIN ||
      userRole === userRoles.SIMSEI_IMPLEMENTATION_MANAGER ||
      userRole === userRoles.FIELD_TEAM_MEMBER
  },
  isManagerialRole (state, getters, rootState) {
    const userRole = rootState.auth.me.role
    return userRole === userRoles.SIMSEI_ADMIN ||
      userRole === userRoles.SIMSEI_IMPLEMENTATION_MANAGER
  },
  isInstructor (state, getters) {
    return getters.isFaculty || getters.isProgramDirector || getters.isViewingAsProctor
  }
}

const actions = {
  resendRegistrationEmailToUser: ({ commit }, existingUser) => {
    return simseiApi.post(`/email/send/resend-registration?email=${encodeURIComponent(existingUser.email)}`)
  },
  bulkResendRegistrationEmailToUsers: ({ commit }, selectedUsers) => {
    return simseiApi.post('/email/send/bulk/resend-registration', selectedUsers)
  },
  fetchInternalUsers: async ({ commit }) => {
    const internalUsers = await simseiApi.get('/user/search/findAllInternalAccounts', {
      params: {
        projection: 'internalUser'
      }
    })
    const users = internalUsers.data._embedded.user
    const officialUsers = users.filter(user => !user.isInternalAdminUser)
    commit('SET_INTERNAL_ACCOUNTS', users)
    commit('SET_OFFICIAL_INTERNAL_ACCOUNTS', officialUsers)
    return users
  },
  createInternalUser: async ({ commit, dispatch }, internalUser) => {
    const res = await simseiApi.post('/user/internal', internalUser)
    dispatch('fetchInternalUsers')
    return res
  },
  editInternalUser: async ({ commit, dispatch }, payload) => {
    const original = payload.original
    const edited = payload.edited
    const res = await simseiApi.patch(`/user/internal?userEmail=${original.email}`, edited)
    dispatch('fetchInternalUsers')
    return res
  },
  deleteInternalUser: async ({ commit, dispatch }, userEmail) => {
    const res = await simseiApi.delete(`/user/internal?userEmail=${userEmail}`)
    dispatch('fetchInternalUsers')
    return res
  },
  registerUser: ({ commit }, { registrationToken, user }) => {
    return simseiApi.patch(`/user/register?token=${registrationToken}`, user)
  },
  findUserByRegistrationToken: async ({ commit }, registrationToken) => {
    const response = await simseiApi.get('/user/search/findByUserDetails_RegistrationToken_Token', {
      params: {
        token: registrationToken,
        projection: 'inlineUserRole'
      }
    })
    return response.data
  },
  resetPasswordEmail: ({ commit }, email) => {
    return simseiApi.post(`/email/send/reset-password?email=${email}`)
  },
  resetPassword: ({ commit }, { resetToken, password }) => {
    return simseiApi.patch(`/user/reset-password?token=${resetToken}`, { password })
  },
  getRoles: async ({ commit }) => {
    const resp = await simseiApi.get('/role')
    return resp.data._embedded.role
  },
  fetchResidentsByGroupId: async ({ commit }, { groupId, projection }) => {
    const params = { id: groupId, projection: projection }
    const response = await simseiApi.get('/user/search/findByGroupId', { params: params })
    return response.data._embedded.user
  },
  fetchFacultyByGroupId: async ({ commit }, { groupId, projection }) => {
    const params = { id: groupId, projection: projection }
    const response = await simseiApi.get('/user/search/findFacultyByGroupId', { params: params })
    return response.data._embedded.user
  },
  fetchResidentsByProgramId: async ({ commit }, { programId, projection }) => {
    const params = { id: programId, projection: projection }
    const response = await simseiApi.get('/user/search/findResidentsByProgramId', { params: params })
    return response.data._embedded.user
  },
  fetchFacultyByProgramId: async ({ commit }, { programId, projection }) => {
    const params = { id: programId, projection: projection }
    const response = await simseiApi.get('/user/search/findFacultyByProgramId', { params: params })
    return response.data._embedded.user
  },
  fetchEnabledUsersByProgramId: async ({ commit }, { pid, projection, enabled }) => {
    const params = { pid: pid, projection: projection, enabled: enabled }
    const response = await simseiApi.get('/user/search/findUsersByProgramIdAndEnabled', { params: params })
    return response.data._embedded.user
  },
  clearUserState: ({ commit }) => {
    commit('CLEAR_USER_STATE')
  }
}

const mutations = {
  SET_INTERNAL_ACCOUNTS (state, accounts) {
    state.internalUsers = accounts
  },
  SET_OFFICIAL_INTERNAL_ACCOUNTS (state, accounts) {
    state.officialInternalUsers = accounts
  },
  SET_INTERNAL_ACCT_EDIT (state, account) {
    state.userToEdit = account
  },
  CLEAR_USER_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
