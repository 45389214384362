import store from '@/residency/store'
import userRoles from '@/residency/consts/user-roles'

const defaultState = () => {
  return {
    showNotificationOverlay: true
  }
}

const state = defaultState()

const getters = {
  showNotificationOverlay: (state) => {
    // Only show notification bell to residents, faculty, and directors
    if (![userRoles.RESIDENT, userRoles.PROG_DIRECTOR, userRoles.FACULTY].includes(store.getters.getUserRole)) {
      return false
    }
    return state.showNotificationOverlay
  }
}

const actions = {
  showNotificationOverlay: ({ commit }) => {
    commit('RESET_OVERLAY_STATE')
    commit('SET_SHOW_NOTIFICATION_OVERLAY', true)
  },
  setDefaultOverlay: ({ commit }) => {
    commit('RESET_OVERLAY_STATE')
  }
}

const mutations = {
  RESET_OVERLAY_STATE (state) {
    Object.assign(state, defaultState())
  },
  SET_SHOW_NOTIFICATION_OVERLAY (state, newState) {
    state.showNotificationOverlay = newState
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
