import { simseiApi } from '@/residency/app-props'
import viewModes from '@/residency/consts/resident-view-modes'
import userGroupAllOption from '@/residency/consts/user-group-all-option.js'

const getDefaultState = () => {
  return {
    userGroups: [],
    currentUserGroupId: null,
    selectedUserGroupId: null,
    videoQueueUserGroupId: userGroupAllOption.id,
    asmtHistoryUserGroupId: userGroupAllOption.id
  }
}

const state = getDefaultState()

const getters = {
  userGroups: state => {
    return state.userGroups
  },
  currentUserGroupId: state => {
    return state.currentUserGroupId
  },
  selectedUserGroupId: state => {
    return state.selectedUserGroupId
  },
  videoQueueUserGroupId: state => {
    return state.videoQueueUserGroupId
  },
  videoQueueUserGroup: (state, rootGetters) => {
    return getGroup(state, rootGetters, state.videoQueueUserGroupId)
  },
  asmtHistoryUserGroupId: state => {
    return state.asmtHistoryUserGroupId
  },
  asmtHistoryUserGroup: (state, rootGetters) => {
    return getGroup(state, rootGetters, state.asmtHistoryUserGroupId)
  },
  currentUserGroup: state => {
    return state.userGroups.find(group => group.id === state.currentUserGroupId)
  },
  selectedUserGroup: (state, rootGetters) => {
    return getGroup(state, rootGetters, state.selectedUserGroupId)
  },
  activeUserGroups: state => {
    return state.userGroups.filter(group => group.isActive)
  },
  selectedAllUserGroupId: (state, getters) => {
    // When "All groups" option is set, we select the first item (user group) from the dropdown list for the dashboard and the courses page.
    // Hence we are grabbing the first item from the active user groups.
    if (state.selectedUserGroupId === userGroupAllOption.id) {
      return chooseActiveOrInactiveUserGroup(getters.activeUserGroups, state.userGroups)
    } else {
      return state.selectedUserGroupId
    }
  }
}

const actions = {
  setUserGroups: ({ commit }, userGroups) => {
    if (userGroups) {
      commit('SET_USER_GROUPS', userGroups)
    }
  },
  setCurrentUserGroupId: ({ commit }, groupId) => {
    commit('SET_CURRENT_USER_GROUP_ID', groupId)
  },
  setSelectedUserGroup: ({ commit }, groupId) => {
    commit('SET_SELECTED_USER_GROUP', groupId)
  },
  fetchUserGroupById: async ({ commit }, groupId) => {
    const res = await simseiApi.get(`/user-group/${groupId}`)
    return res.data
  },
  clearUserGroups: ({ commit }) => {
    commit('RESET_USER_GROUPS')
  }
}

const mutations = {
  SET_USER_GROUPS (state, userGroups) {
    state.userGroups = userGroups
  },
  SET_CURRENT_USER_GROUP_ID (state, groupId) {
    state.currentUserGroupId = groupId
  },
  SET_SELECTED_USER_GROUP (state, groupId) {
    state.selectedUserGroupId = groupId
  },
  SET_VIDEO_QUEUE_USER_GROUP_ID (state, groupId) {
    state.videoQueueUserGroupId = groupId
  },
  SET_ASMT_HISTORY_USER_GROUP_ID (state, groupId) {
    state.asmtHistoryUserGroupId = groupId
  },
  RESET_USER_GROUPS (state) {
    Object.assign(state, getDefaultState())
  }
}

const getGroup = (state, rootGetters, groupId) => {
  if (rootGetters.proctorViewMode === viewModes.PROCTOR) {
    const allProctorGroups = [...rootGetters.proctorGroups, ...rootGetters.expiredProctorGroups]
    return allProctorGroups.find(group => group.id === groupId)
  } else {
    if (groupId === userGroupAllOption.id) return userGroupAllOption
    return state.userGroups.find(group => group.id === groupId)
  }
}

// Choose the first active user group, if there are no active user groups
// then choose the first inactive user group
const chooseActiveOrInactiveUserGroup = (activeUserGroups, allUserGroups) => {
  return activeUserGroups.length
    ? activeUserGroups[0].id
    : allUserGroups[0].id
}

export default {
  state,
  getters,
  actions,
  mutations
}
