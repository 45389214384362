<template>
  <teleport to="body">
    <transition name="bounce">
      <dialog
        v-if="isOpen"
        ref="modal"
        id="modal"
        :class="['modal', conditionalClasses, contentClass]"
        :style="cssStyles"
        v-bind="$attrs"
        @click.stop="onClick"
        @cancel="onEscPressed"
        @close="close"
      >
        <div ref="content" :class="{'modal-content-fullscreen': fullscreen}">
          <slot />
        </div>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
import { useBeforeRouteChange } from '@/composables/useBeforeRouteChange'

export default {
  name: 'BaseModal',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    width: {
      type: [String, Number],
      required: false,
      default: '500'
    },
    maxWidth: {
      type: [String, Number],
      required: false
    },
    maxHeight: {
      type: [String, Number],
      required: false
    },
    scrim: {
      type: Boolean,
      required: false,
      default: true
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },
    // disables bounce effect when clicking outside persistent modals
    noClickAnimation: {
      type: Boolean,
      required: false,
      default: false
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue', 'clickOutside'],
  setup () {
    const { setBeforeRouteChangeCallback } = useBeforeRouteChange()
    return { setBeforeRouteChangeCallback }
  },
  data () {
    return {
      isShaking: false,
      scrollPosition: 0,
      isOpen: false,
      bodyOverflow: 'hidden',
      modalWidth: 0
    }
  },
  computed: {
    scrimOpacity () {
      return this.scrim ? 1 : 0
    },
    modalMaxWidth () {
      return this.maxWidth ? this.formattedDimension(this.maxWidth) : this.formattedDimension(this.width)
    },
    modalMaxHeight () {
      return this.maxHeight ? this.formattedDimension(this.maxHeight) : '100%'
    },
    conditionalClasses () {
      return {
        'shake': this.isShaking,
        'fullscreen': this.fullscreen
      }
    },
    cssStyles () {
      const styleObj = {
        width: this.modalWidth,
        overflow: this.bodyOverflow
      }

      if (this.maxWidth) {
        styleObj.maxWidth = this.modalMaxWidth
      }

      if (this.maxHeight) {
        styleObj.maxHeight = this.modalMaxHeight
        styleObj.height = '100%'
      }

      return styleObj
    }
  },
  watch: {
    modelValue (value) {
      if (value) {
        this.open()
      } else {
        this.close()
      }
    }
  },
  mounted () {
    if (this.modelValue) {
      this.open()
    }
    this.setBeforeRouteChangeCallback(this.onBeforeRouteChange)
    window.addEventListener('resize', this.updateBodyOverflow)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updateBodyOverflow)
    this.close()
  },
  methods: {
    open () {
      this.isOpen = true

      this.preventBodyScroll()

      this.$nextTick(() => {
        const width = this.maxWidth ? '100%' : this.width
        this.modalWidth = this.formattedDimension(width)
        this.$refs.modal.showModal()

        // prevent focus from being applied to child elements in the <dialog> element by focusing on the parent
        // https://html.spec.whatwg.org/multipage/interactive-elements.html#dialog-focusing-steps
        this.$refs.modal.focus()
        this.$refs.modal.blur()
        this.updateBodyOverflow()
      })
    },
    // Repeated use of the 'esc' key will trigger the @close event in chrome
    // https://issues.chromium.org/issues/351867704
    close () {
      if (!this.isOpen) return

      this.allowBodyScroll()

      this.$refs.modal.close()
      this.$emit('update:modelValue', false)
      this.isOpen = false
    },
    onEscPressed (e) {
      // If a file selector which opened from the modal is closed, a `cancel` event is triggered on the `dialog` element.
      // This event should not close the modal.
      if (e.target.type === 'file') return

      if (!this.persistent) {
        this.close()
      } else {
        e.preventDefault()
        this.shake()
      }
    },
    onClick (e) {
      if (this.modelValue && this.isClickOutside(e)) {
        this.onClickOutside()
      }
    },
    isClickOutside (e) {
      if (!this.$refs.modal) {
        return false // check if element was unmounted by v-if
      }

      // When an element such as file selector, or time selector is opened inside the modal, the clientX and clientY are 0
      if (!e.clientX || !e.clientY) return false

      const modalRect = this.$refs.modal.getBoundingClientRect()
      return e.clientX < modalRect.left ||
        e.clientX > modalRect.right ||
        e.clientY < modalRect.top ||
        e.clientY > modalRect.bottom
    },
    onClickOutside () {
      this.$emit('clickOutside')

      if (!this.persistent) {
        this.close()
      } else if (!this.noClickAnimation) {
        this.shake()
      }
    },
    shake () {
      this.isShaking = true
      setTimeout(() => {
        this.isShaking = false
      }, 1000)
    },
    formattedDimension (value) {
      if (isNaN(value)) {
        return value
      } else {
        return `${value}px`
      }
    },
    preventBodyScroll () {
      this.scrollPosition = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
      // hold current position (otherwise the page shoots to the top)
      document.body.style.top = `-${this.scrollPosition}px`
    },
    allowBodyScroll () {
      document.body.style.position = ''
      document.body.style.width = ''
      document.body.style.top = ''
      window.scrollTo(0, this.scrollPosition)
    },
    onBeforeRouteChange (to, from, next, backButtonUsed) {
      if (this.persistent && this.isOpen && backButtonUsed) {
        this.shake()
        next(false)
      } else {
        next()
      }
    },
    /**
     * It compares the height of the modal content with the height of the modal itself.
     * If the height of the modal content is greater than the height of the modal,
     * it sets the body overflow to 'auto', enabling scrollbars when the content overflows.
     * This prevents the content from being cut off.
     */
    updateBodyOverflow () {
      if (!this.isOpen) return
      const modalHeight = this.$refs.modal.offsetHeight
      const contentHeight = this.$refs.content.offsetHeight
      if (contentHeight >= modalHeight) {
        this.bodyOverflow = 'auto'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/_mixins.scss";

@include scrollbar-redesign;

.modal {
  margin: auto;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  color: $neutral-typography-dark;
}

#modal::backdrop {
  opacity: v-bind(scrimOpacity);
  background: rgba(0, 0, 0, 0.32);
  animation: color-blend 0.5s;
}

@keyframes color-blend {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.32);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.fullscreen {
  max-width: 100vw;
  width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: 0;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.bounce-enter-active {
  animation: bounce-in 0.2s;
}

.bounce-leave-active {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    background: rgba(0, 0, 0, 0);
    transform: scale(0.9);
  }

  100% {
    background: rgba(0, 1, 0, 0.32);
    transform: scale(1);
  }
}

.modal-content-fullscreen {
  background-color: $surface-color;
  height: 100%;
}
</style>
