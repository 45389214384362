import { simseiApi } from '@/residency/app-props'
import { toKebabCase } from '@/utils/string-util'

const getDefaultState = () => {
  return {
    editableCourses: [],
    availableVersions: {}
  }
}

const state = getDefaultState()

const getters = {
  adminEditableCourses (state) {
    return state.editableCourses
  },
  adminAvailableVersions (state) {
    return state.availableVersions
  }
}

const actions = {
  requestAllSelectedUserGroupCourses: async ({ commit, rootGetters }) => {
    const response = await simseiApi.get('/user-group-course/findByUserGroupId/', {
      params: {
        userGroupId: rootGetters.selectedAllUserGroupId
      }
    })
    if (response.data) {
      const editableCourses = response.data
        .map(courseEntity => createEditableCourse(courseEntity))

      commit('SET_EDITABLE_COURSES', editableCourses)
    } else {
      commit('SET_EDITABLE_COURSES', [])
    }
  },
  clearAdminEditableCourses ({ commit }) {
    commit('SET_EDITABLE_COURSES', [])
  },
  getCourseVersions: async ({ commit }) => {
    const courseVersions = {}
    const response = await simseiApi.get('/course/versions')
    response.data.forEach(ele => {
      const versions = ele.courseVersions
      const courseName = ele.courseName
      courseVersions[courseName] = versions
    })
    commit('SET_AVAILABLE_VERSIONS', courseVersions)
  },
  updateCourseVersion: async ({ commit, state }, payload) => {
    try {
      const response = await simseiApi.patch(`/user-group-course/${payload.groupCourseId}/version-change`, null, {
        params: { version: payload.newVersion }
      })

      if (response.status === 200) {
        const updatedCourses = state.editableCourses.map(course => {
          if (course.name === payload.courseName) {
            course.version = payload.newVersion
          }
          return course
        })

        commit('SET_EDITABLE_COURSES', updatedCourses)
      }
    } catch (error) {
      this.$log.error(error)
    }
  }
}

function createEditableCourse (groupCourse) {
  const course = groupCourse.course
  groupCourse.activities.forEach(activity => {
    activity.kebabName = toKebabCase(activity.name)
    delete activity._links
  })
  return {
    id: course.id,
    groupCourseId: groupCourse.id,
    name: course.name,
    kebabName: toKebabCase(course.name),
    courseType: course.courseType,
    isLocked: groupCourse.isLocked,
    order: groupCourse.courseOrder,
    activities: groupCourse.activities,
    hiddenActivities: groupCourse.hiddenActivities,
    version: groupCourse.version
  }
}

const mutations = {
  SET_EDITABLE_COURSES (state, payload) {
    state.editableCourses = payload
  },
  SET_AVAILABLE_VERSIONS (state, payload) {
    state.availableVersions = payload
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
