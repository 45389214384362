// Use this store for any filter component that may require shared state
// and for text filter on admin homepage

const defaultState = () => {
  return {
    textSearchFilter: ''
  }
}

const state = defaultState()

export const getters = {
  textSearchFilter (state) {
    return state.textSearchFilter
  }
}

export const actions = {
  setTextSearchFilter: ({ commit }, filterValue) => {
    commit('SET_TEXT_SEARCH_FILTER', filterValue)
  }
}

export const mutations = {
  SET_TEXT_SEARCH_FILTER (state, filterValue) {
    state.textSearchFilter = filterValue
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
