import { simseiApi } from '@/residency/app-props'

const actions = {
  postRouteInteraction: ({ commit }, interaction) => {
    return simseiApi.post('/vue-route-interaction', interaction)
  }
}

export default {
  actions
}
