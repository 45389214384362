import { toKebabCase } from '@/utils/string-util'
import { simseiApi } from '@/residency/app-props'
import userRoles from '@/residency/consts/user-roles'
import cdn from '@/consts/cdn'
import { courseEnum } from '@/residency/views/courses/course-enum'
import courseTypes from '@/residency/consts/course-types'
import noCache from '@/residency/consts/nocache-headers.js'

const getDefaultState = () => {
  return {
    selectedCourse: {
      courseType: '',
      id: '',
      name: '',
      activities: [],
      hasCognitiveAsmt: null
    },
    availableCourses: [],
    currentActivityId: '',
    programCourses: [],
    dashboardSelectedCourse: {}
  }
}
const state = getDefaultState()

const actions = {
  updateAvailableCourses ({ dispatch, commit, rootGetters }) {
    commit('SET_AVAILABLE_COURSES', [])
    if (rootGetters.isViewingAsProctor) {
      return dispatch('requestGroupCourses')
    }
    switch (rootGetters.me.role) {
      case userRoles.SIMSEI_ADMIN:
      case userRoles.SIMSEI_IMPLEMENTATION_MANAGER:
      case userRoles.FIELD_TEAM_MEMBER:
        return dispatch('requestAllCourses')
      case userRoles.RESIDENT:
        if (!rootGetters.me.isActive) {
          return dispatch('requestAllCourses')
        }
        return dispatch('requestResidentGroupCourses')
      case userRoles.FACULTY:
      case userRoles.PROG_DIRECTOR:
        return dispatch('requestGroupCourses')
      default:
        throw new Error('Invalid role type!')
    }
  },
  requestAllCourses: ({ commit }) => {
    return simseiApi.get('/course?projection=inlineActivities').then(response => {
      const availCourses = []
      response.data._embedded.course.forEach(course => {
        const courseObj = {
          id: course.id,
          name: course.name,
          kebabName: toKebabCase(course.name),
          courseType: course.courseType,
          isLocked: false,
          order: -1,
          activities: course.activities
        }
        availCourses.push(courseObj)
      })
      commit('SET_AVAILABLE_COURSES', availCourses)
      return availCourses
    }).catch(error => {
      throw error
    })
  },
  requestResidentGroupCourses: async ({ commit, rootGetters }, groupId) => {
    if (!rootGetters.me.isActive) return

    const selectedGroupId = groupId || rootGetters.currentUserGroupId
    const response = await simseiApi.get(`/user-group-course/resident/${selectedGroupId}`)
    const availableCourses = response.data
      .map(courseEntity => createAvailableCourse(courseEntity))
      .filter(course => course.name !== courseEnum.FACULTY_ORIENTATION.titleCase)
    commit('SET_AVAILABLE_COURSES', availableCourses)

    return availableCourses
  },
  requestGroupCourses: async ({ commit, rootGetters }) => {
    if (!rootGetters.selectedUserGroupId) return

    const response = await simseiApi.get(`/user-group-course/faculty/${rootGetters.selectedAllUserGroupId}`)
    const availableCourses = response.data
      .map(courseEntity => createAvailableCourse(courseEntity))
      .filter(course => course.name !== courseEnum.RESIDENT_ORIENTATION.titleCase)
    commit('SET_AVAILABLE_COURSES', availableCourses)

    return availableCourses
  },
  requestProgramActivities: async ({ state, commit, rootGetters }) => {
    if (state.programCourses.length) return state.programCourses

    const res = await simseiApi.get(`/program/faculty/all-activities/${rootGetters.programId}`)
    const availableCourses = res.data.map(courseActivities => createAvailableCourse(courseActivities))

    commit('SET_PROGRAM_COURSES', availableCourses)

    return availableCourses
  },
  clearCourses: ({ commit }) => {
    commit('CLEAR_COURSES')
  },
  clearSelectedCourse: ({ commit }) => {
    commit('SET_SELECTED_COURSE', getDefaultState.selectedCourse)
  },
  setCurrentActivityId: ({ commit }, activityId) => {
    commit('SET_CURRENT_ACTIVITY_ID', activityId)
  },
  requestGroupCoursesForCurricularHistory: async ({ commit }, groupId) => {
    const response = await simseiApi.get(`/user-group-course/resident/${groupId}`, {
      headers: {
        ...noCache
      }
    })
    const availableCourses = response.data
      .map(courseEntity => createAvailableCourse(courseEntity))
      .filter(course => course.name !== courseEnum.RESIDENT_ORIENTATION.titleCase &&
        course.courseType !== courseTypes.SOFT_SKILL.id)
    return availableCourses
  }
}

function createAvailableCourse (groupCourse) {
  const course = groupCourse.course
  groupCourse.activities.forEach(activity => {
    activity.kebabName = toKebabCase(activity.name)
    delete activity._links
  })
  return {
    id: course.id,
    name: course.name,
    kebabName: toKebabCase(course.name),
    courseType: course.courseType,
    isLocked: groupCourse.isLocked,
    order: groupCourse.courseOrder,
    activities: groupCourse.activities,
    version: groupCourse.version,
    hasCognitiveAsmt: groupCourse.hasCognitiveAsmt
  }
}

const getters = {
  selectedCourse (state) {
    return state.selectedCourse
  },
  availableCourses (state) {
    return state.availableCourses
  },
  programCourses (state) {
    return state.programCourses
  },
  selectedCoursePath (state) {
    return toKebabCase(state.selectedCourse.name)
  },
  courseById: (state, rootGetters) => (id) => {
    if (rootGetters.isInstructor) {
      return state.programCourses.find(course => course.id === id)
    }
    return state.availableCourses.find(course => course.id === id)
  },
  courseIconCdnLink: () => (courseName) => {
    const envFolder = process.env.VUE_APP_ENV === 'production' ? 'prd' : 'stg'
    return `${cdn.BRANDING}/course_icons/${envFolder}/${toKebabCase(courseName)}.svg`
  },
  currentActivityId (state) {
    return state.currentActivityId
  },
  dashboardSelectedCourse (state) {
    return state.dashboardSelectedCourse
  },
  isRhdPilotCourse (state) {
    return state.selectedCourse.name === courseEnum.RENAL_HILUM_DISSECTION.titleCase
  }
}

const mutations = {
  SET_SELECTED_COURSE (state, payload) {
    state.selectedCourse = payload
  },
  SET_AVAILABLE_COURSES (state, payload) {
    state.availableCourses = payload
  },
  SET_PROGRAM_COURSES (state, payload) {
    state.programCourses = payload
  },
  CLEAR_COURSES (state) {
    Object.assign(state, getDefaultState())
  },
  SET_CURRENT_ACTIVITY_ID (state, payload) {
    state.currentActivityId = payload
  },
  SET_DASHBOARD_SELECTED_COURSE (state, course) {
    state.dashboardSelectedCourse = course
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
