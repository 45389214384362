import { simseiApi } from '@/residency/app-props'
import { arSubActivityIdToArActivityIdMapping, isArTutorial } from '@/residency/views/ar-cam-nav/consts/ar-subactivities.js'
import feedbackEnum from '@/residency/views/ar-cam-nav/consts/feedback-enum'
import log from '@/residency/utils/log'

const getDefaultState = () => {
  return {
    arHistory: {},
    arSelectedActivityId: null,
    arSelectedSubActivityId: null,
    arSelectedLevel: null,
    arLevelMetrics: null,
    isGameInProgress: false,
    isArTutorialInProgress: false,
    isArTutorialCompleted: false,
    arMetric: null,
    isViewingArHomeScreen: true
  }
}

const state = getDefaultState()

const getters = {
  arHistory (state) {
    return state.arHistory
  },
  arSelectedActivityId (state) {
    return state.arSelectedActivityId
  },
  arSelectedSubActivityId (state) {
    return state.arSelectedSubActivityId
  },
  arSelectedLevel (state) {
    return state.arSelectedLevel
  },
  arLevelMetrics (state) {
    return state.arLevelMetrics
  },
  isGameInProgress (state) {
    return state.isGameInProgress
  },
  isArTutorialInProgress (state) {
    return state.isArTutorialInProgress
  },
  isArTutorialCompleted (state) {
    return state.isArTutorialCompleted
  },
  arMetric (state) {
    return state.arMetric
  },
  isViewingArHomeScreen (state) {
    return state.isViewingArHomeScreen
  }
}

const actions = {
  fetchArSubActivityMetrics: async ({ commit, rootGetters }) => {
    if (rootGetters.arMetric !== null) {
      return
    }
    try {
      const resp = await simseiApi.get('/arSubActivity')
      const data = resp.data._embedded.arSubActivity

      const subActivityMetrics = {}
      for (const subActivity of data) {
        const subActivityMetricMap = {}
        subActivity.metrics.forEach(metric => {
          subActivityMetricMap[metric.name] = {
            passingScore: metric.passingScore,
            metricOrder: metric.metricOrder
          }
        })
        subActivityMetrics[subActivity.id] = subActivityMetricMap
      }

      commit('SET_AR_METRIC', subActivityMetrics)
    } catch (error) {
      log.error(error)
    }
  },
  setSelectedArSubActivity: ({ commit }, subActivityId) => {
    const activityId = arSubActivityIdToArActivityIdMapping[subActivityId]
    if (!activityId) {
      log.error(`Invalid subActivityId: ${subActivityId}`)
      return
    }
    commit('SET_AR_SELECTED_ACTIVITY', activityId)
    commit('SET_AR_SELECTED_SUBACTIVITY', subActivityId)
    commit('SET_IS_AR_VIEWING_HOME_SCREEN', false)
  },
  setSelectedArActivity: ({ commit }, activityId) => {
    commit('SET_AR_SELECTED_ACTIVITY', activityId)
  },
  getArOverallHistory: async ({ commit, rootGetters }) => {
    const arCamNavActivityId = rootGetters.arSelectedActivityId
    if (!arCamNavActivityId) return

    let overallProgressResult
    if (rootGetters.isInstructor) {
      overallProgressResult = await simseiApi.get(`/ar-attempt/faculty/overall-progress/${arCamNavActivityId}`)
    } else {
      const currentUserGroupId = rootGetters.currentUserGroupId
      overallProgressResult = await simseiApi.get(`/ar-attempt/resident/overall-progress/${arCamNavActivityId}?userGroupId=${currentUserGroupId}`)
    }

    const overallProgress = overallProgressResult.data

    const arHistory = {}
    for (const activity of overallProgress) {
      arHistory[activity.subActivityId] = activity
    }

    commit('SET_AR_HISTORY', arHistory)
    return arHistory
  },
  clearArOverallHistory: ({ commit, rootGetters }) => {
    commit('CLEAR_AR_HISTORY')
  },
  startArActivityLevel: ({ commit }, level) => {
    commit('SET_AR_SELECTED_LEVEL', level)
    commit('SET_IS_GAME_IN_PROGRESS', true)
    commit('SET_AR_LEVEL_METRICS', null)
  },
  endArActivityLevel: ({ commit }, metrics) => {
    commit('SET_IS_GAME_IN_PROGRESS', false)
    commit('SET_AR_LEVEL_METRICS', metrics)
  },
  resetArActivity: ({ commit }) => {
    commit('SET_IS_AR_VIEWING_HOME_SCREEN', true)
    commit('SET_AR_SELECTED_SUBACTIVITY', null)
    commit('SET_AR_SELECTED_LEVEL', null)
    commit('SET_AR_TUTORIAL_IN_PROGRESS', false)
    commit('SET_AR_TUTORIAL_COMPLETED', false)
  },
  submitArAttempt: ({ commit, rootGetters }, { subActivityId, level, metrics, notes, elapsedSeconds }) => {
    if (rootGetters.arSelectedSubActivityId !== subActivityId && !isArTutorial(subActivityId)) {
      log.error(`Mismatch between the selected activity (${rootGetters.arSelectedSubActivityId}) in the store and activity played (subActivityId: ${subActivityId})`)
    }
    const scores = []
    for (const [key, value] of Object.entries(metrics)) {
      scores.push({
        metricType: key.toUpperCase(),
        score: value
      })
    }
    const notesList = checkNotes(notes)
    const userType = rootGetters.isInstructor ? 'faculty' : 'resident'
    simseiApi.post(`/ar-attempt/${userType}/submit/` + rootGetters.arSelectedActivityId, {
      subActivityId,
      level,
      exerciseScores: scores,
      analysisNotes: notesList,
      elapsedSeconds
    })

    simseiApi.post(`/activity-guide-progress/complete-activity-guide?activityId=${rootGetters.arSelectedActivityId}`).then(() => {
      commit('SET_AR_ACTIVITY_GUIDE_VIEWED')
    })
  },
  startArTutorial: ({ commit }) => {
    commit('SET_AR_TUTORIAL_IN_PROGRESS', true)
    commit('SET_AR_TUTORIAL_COMPLETED', false)
    commit('SET_IS_AR_VIEWING_HOME_SCREEN', false)
  },
  completeArTutorial: ({ commit, dispatch, rootGetters }, attempt) => {
    commit('SET_AR_TUTORIAL_IN_PROGRESS', false)
    commit('SET_AR_TUTORIAL_COMPLETED', true)
    dispatch('submitArAttempt', attempt)
  },
  exitArGame: ({ commit }) => {
    const {
      arSelectedSubActivityId,
      arSelectedLevel,
      isGameInProgress,
      isArTutorialInProgress,
      isArTutorialCompleted,
      isViewingArHomeScreen
    } = getDefaultState()

    commit('SET_AR_SELECTED_SUBACTIVITY', arSelectedSubActivityId)
    commit('SET_AR_SELECTED_LEVEL', arSelectedLevel)
    commit('SET_IS_GAME_IN_PROGRESS', isGameInProgress)
    commit('SET_AR_TUTORIAL_IN_PROGRESS', isArTutorialInProgress)
    commit('SET_AR_TUTORIAL_COMPLETED', isArTutorialCompleted)
    commit('SET_IS_AR_VIEWING_HOME_SCREEN', isViewingArHomeScreen)
  }
}

const mutations = {
  SET_AR_HISTORY (state, history) {
    state.arHistory = history
  },
  CLEAR_AR_HISTORY (state) {
    state.arHistory = {}
  },
  SET_AR_SELECTED_ACTIVITY (state, activity) {
    state.arSelectedActivityId = activity
    state.arSelectedSubActivityId = null
  },
  SET_AR_SELECTED_SUBACTIVITY (state, subActivity) {
    state.arSelectedSubActivityId = subActivity
  },
  SET_AR_SELECTED_LEVEL (state, level) {
    state.arSelectedLevel = level
  },
  SET_AR_LEVEL_METRICS (state, score) {
    state.arLevelMetrics = score
  },
  SET_IS_GAME_IN_PROGRESS (state, status) {
    state.isGameInProgress = status
  },
  SET_AR_TUTORIAL_IN_PROGRESS (state, status) {
    state.isArTutorialInProgress = status
  },
  SET_AR_TUTORIAL_COMPLETED (state, status) {
    state.isArTutorialCompleted = status
  },
  SET_AR_METRIC (state, metrics) {
    state.arMetric = metrics
  },
  SET_IS_AR_VIEWING_HOME_SCREEN (state, status) {
    state.isViewingArHomeScreen = status
  },
  CLEAR_AR_GAME_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

function checkNotes (notes) {
  const emptyNotes = []

  // Focus, Star and Tube activity do not have any analysis notes
  if (!notes?.length) {
    return emptyNotes
  }

  // We check to see if the game sent us any notes
  // if it does, we return the original array back
  for (const note of notes) {
    if (note.note !== feedbackEnum[0]) { // feedbackEnum[0] is 'NONE'
      return notes
    }
  }

  // User has a passing attempt and the game did not
  // send us back any analysis notes.
  return emptyNotes
}
