import { workshopApi } from '@/workshop/workshop-api'

const actions = {
  postRouteInteraction: ({ commit }, interaction) => {
    return workshopApi.post('/workshop-route-interaction', interaction)
  }
}

export default {
  actions
}
