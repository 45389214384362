import store from '@/residency/store/index'

/**
 * This module adds and removes calendar events from the store.
 */
const response = {
  // add a new event to the store
  '/user/topic/schedule/update': (res) => {
    const event = JSON.parse(res.body)
    store.dispatch('addScheduleEventFromWebsocket', event)
  },
  // remove an event from the store
  '/user/topic/schedule/delete': (res) => {
    const eventId = JSON.parse(res.body)
    store.commit('DELETE_NOTIFICATION', eventId)
    store.commit('DELETE_SCHEDULE_EVENT', eventId)
  }
}

export default {
  response
}
