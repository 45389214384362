import { simseiApi } from '@/residency/app-props'

const getDefaultState = () => {
  return {
    progressOfMembers: {},
    courseNames: [],
    programGroups: []
  }
}

const state = getDefaultState()

const getters = {
  programProgress: state => {
    return state.progressOfMembers
  },
  allCourseNames: state => {
    return state.courseNames
  },
  programGroups: state => {
    return state.programGroups
  }
}

const actions = {
  updateAdminCourseProgressByPid: async ({ commit }, pid) => {
    const res = await simseiApi.get('/user-group/search/findByProgramIdOrderByCreationDate', {
      params: {
        id: pid,
        projection: 'groupNameIdAndIsUnassigned'
      }
    })

    const progressMap = {}
    const groups = res.data._embedded.userGroup
    const instructorProgressRes = await simseiApi.get(`/progress/admin-instructors/${pid}`)

    commit('SET_PROGRAM_GROUPS', groups)

    const promises = []
    for (const group of groups) {
      if (!group.isUnassignedGroup) {
        const progressPromise = simseiApi.get(`/progress/admin/${group.id}`)
        promises.push(progressPromise)
      }
    }

    const data = await Promise.all(promises)
    data.forEach((groupProgress, idx) => {
      progressMap[groups[idx].id] = {
        name: groups[idx].name,
        progressData: groupProgress.data
      }
    })

    // Assign an id and user group `name` as 'NONE', since faculties, PD don't have a user group
    progressMap['NONE'] = {
      name: 'NONE',
      progressData: instructorProgressRes.data
    }
    commit('SET_PROGRESS_OF_MEMBERS', progressMap)
  },
  fetchAllCourseInfo: async ({ commit }) => {
    const res = await simseiApi.get('/course')
    const names = res.data._embedded.course.map(course => course.name)
    commit('SET_ALL_COURSE_NAMES', names)
  }
}

const mutations = {
  SET_PROGRESS_OF_MEMBERS (state, mapping) {
    state.progressOfMembers = mapping
  },
  SET_ALL_COURSE_NAMES (state, names) {
    state.courseNames = names
  },
  SET_PROGRAM_GROUPS (state, groups) {
    state.programGroups = groups
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
