import videoFeedbackTypes from '@/residency/consts/video-feedback-types'
import { videoFilterEnums } from '@/residency/views/video/video-enums'

const getDefaultState = () => {
  return {
    /* Recommended Video Object
      {
        sharedVideo: { id: sharedVideoId },
        comment: 'This is a Lorem Ipsum comment',
        timeStamp: 3.33,
        videoLength: '777',
        thumbnail: null,
        assessor: name,
        creationDate: '07-07-7777'
        videoAsmtId: 'ABCDEFG-1234-4566-DHSH-3283728YDU'
      }
    */
    recommendedVideos: [],
    comments: [],
    selectedVideo: {},
    selectedVideoType: '',
    searchText: '',
    videoFeedbackTimestamps: [],
    currentVideoAsmtId: null,
    videosCurrentTimestamp: {}
  }
}

const state = getDefaultState()

const getters = {
  videoFeedbackTimestamps: state => state.videoFeedbackTimestamps,
  videoFeedback (state) {
    return state
  },
  videoFeedbackRecommendedVideos (state) {
    return state.recommendedVideos
  },
  videoFeedbackComments (state) {
    return state.comments
  },
  videoFeedbackSelectedVideo (state) {
    return state.selectedVideo
  },
  videoFeedbackSelectedVideoType (state) {
    return state.selectedVideoType
  },
  videoFeedbackSearchText (state) {
    return state.searchText
  },
  currentVideoAsmtId (state) {
    return state.currentVideoAsmtId
  },
  videosCurrentTimestamp (state) {
    return state.videosCurrentTimestamp
  }
}

const actions = {
  addVideoFeedbackRecommendedVideo: ({ commit, state }, recommendedVideo) => {
    commit('SET_RECOMMENDED_VIDEOS', [...state.recommendedVideos, recommendedVideo])
    commit('ADD_FEEDBACK_TIMESTAMP', {
      timeStamp: recommendedVideo.timeStamp,
      videoFeedbackType: videoFeedbackTypes.RECOMMENDATION,
      feedbackId: recommendedVideo.recommendedVideoId
    })
  },
  removeVideoFeedbackRecommendedVideo: ({ commit }, recommendedVideo) => {
    commit('REMOVE_RECOMMENDED_VIDEO', recommendedVideo.recommendedVideoId)
  },
  addVideoFeedbackComment: ({ commit }, comment) => {
    commit('ADD_COMMENT', comment)
    commit('ADD_FEEDBACK_TIMESTAMP', {
      timeStamp: comment.timeStamp,
      videoFeedbackType: videoFeedbackTypes.COMMENT,
      duration: comment.duration || 0,
      feedbackId: comment.commentId
    })
  },
  setVideoFeedbackState: ({ commit }, { recommendedVideos, timestamps }) => {
    commit('SET_RECOMMENDED_VIDEOS', recommendedVideos)
    commit('SET_VIDEO_FEEDBACK_TIMESTAMPS', timestamps)
  },
  setVideoFeedbackSelectedVideo: ({ commit }, selectedVideo) => {
    commit('SET_SELECTED_VIDEO', selectedVideo)
  },
  setVideoFeedbackSelectedVideoType: ({ commit }, selectedVideoType) => {
    commit('SET_SELECTED_VIDEO_TYPE', selectedVideoType)
  },
  setVideoFeedbackSearchText: ({ commit }, searchText) => {
    commit('SET_SEARCH_TEXT', searchText)
  },
  clearRecommendedModal: ({ commit }) => {
    commit('CLEAR_RECOMMENDED_MODAL')
  },
  resetVideoFeedbackState: ({ commit }) => {
    commit('RESET_VIDEO_FEEDBACK_STATE')
  },
  setCurrentVideoAsmtId: ({ commit }, videoAsmtId) => {
    commit('SET_CURRENT_VIDEO_ASMT_ID', videoAsmtId)
  },
  editVideoFeedbackComment: ({ commit }, { commentId, updatedComment }) => {
    commit('EDIT_VIDEO_FEEDBACK_COMMENT', { commentId, updatedComment })
  },
  deleteVideoFeedbackComment: ({ commit }, commentId) => {
    commit('DELETE_VIDEO_FEEDBACK_COMMENT', commentId)
  }
}

const mutations = {
  SET_VIDEO_FEEDBACK_TIMESTAMPS (state, feedback) {
    state.videoFeedbackTimestamps = feedback
  },
  SET_RECOMMENDED_VIDEOS (state, recommendedVideo) {
    state.recommendedVideos = recommendedVideo
  },
  SET_COMMENTS (state, comments) {
    state.comments = comments
  },
  ADD_COMMENT (state, comment) {
    state.comments.push(comment)
  },
  ADD_FEEDBACK_TIMESTAMP (state, { timeStamp, videoFeedbackType, duration, feedbackId }) {
    state.videoFeedbackTimestamps.push({ timeStamp, videoFeedbackType, duration, feedbackId })
  },
  REMOVE_RECOMMENDED_VIDEO (state, id) {
    const index = state.recommendedVideos.findIndex(video => video.recommendedVideoId === id)
    const timestampIndex = state.videoFeedbackTimestamps.findIndex(fb =>
      fb.videoFeedbackType === videoFeedbackTypes.RECOMMENDATION && fb.feedbackId === id)
    state.recommendedVideos.splice(index, 1)
    state.videoFeedbackTimestamps.splice(timestampIndex, 1)
  },
  SET_SELECTED_VIDEO (state, selectedVideo) {
    state.selectedVideo = selectedVideo
  },
  SET_SELECTED_VIDEO_TYPE (state, selectedVideoType) {
    state.selectedVideoType = selectedVideoType
  },
  SET_SEARCH_TEXT (state, searchText) {
    state.searchText = searchText
  },
  CLEAR_RECOMMENDED_MODAL (state) {
    state.selectedVideo = {}
    state.selectedVideoType = videoFilterEnums.FAVORITES
    state.searchText = ''
  },
  RESET_VIDEO_FEEDBACK_STATE (state) {
    Object.assign(state, getDefaultState())
  },
  SET_CURRENT_VIDEO_ASMT_ID (state, videoAsmtId) {
    state.currentVideoAsmtId = videoAsmtId
  },
  EDIT_VIDEO_FEEDBACK_COMMENT (state, { commentId, updatedComment }) {
    const index = state.comments.findIndex(comment => comment.commentId === commentId)
    state.comments[index].comment = updatedComment
  },
  DELETE_VIDEO_FEEDBACK_COMMENT (state, commentId) {
    const index = state.comments.findIndex(comment => comment.commentId === commentId)
    const timestampIndex = state.videoFeedbackTimestamps.findIndex(fb =>
      fb.videoFeedbackType === videoFeedbackTypes.COMMENT && fb.feedbackId === commentId)
    state.comments.splice(index, 1)
    state.videoFeedbackTimestamps.splice(timestampIndex, 1)
  },
  SET_VIDEO_CURRENT_TIMESTAMP (state, video) {
    const { id, currentTime } = video
    if (state.videosCurrentTimestamp.hasOwnProperty(id)) {
      state.videosCurrentTimestamp[id].currentTime = currentTime
    } else {
      state.videosCurrentTimestamp[video.id] = { currentTime }
    }
  },
  REMOVE_VIDEO_CURRENT_TIMESTAMP (state, videoAsmtId) {
    if (state.videosCurrentTimestamp.hasOwnProperty(videoAsmtId)) {
      delete state.videosCurrentTimestamp[videoAsmtId]
    }
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
