import { workshopApi } from '@/workshop/workshop-api'

const getDefaultState = () => {
  return {
    procedures: []
  }
}

const state = getDefaultState()

const getters = {
  procedures (state) {
    return state.procedures
  }
}

const actions = {
  fetchWorkshopProcedures: async ({ commit, getters }) => {
    if (getters.procedures.length) return
    const res = await workshopApi.get('/workshop-procedure')
    const procedures = res.data._embedded.workshopProcedure.map(procedure => {
      return {
        id: procedure.id,
        text: procedure.name,
        shortText: procedure.shortText
      }
    })
    commit('SET_WORKSHOP_PROCEDURES', procedures)
  }
}

const mutations = {
  SET_WORKSHOP_PROCEDURES (state, procedures) {
    state.procedures = procedures
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
