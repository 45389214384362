import { simseiApi } from '@/residency/app-props'
import { parseEventDeletedNotification, isCardNotification } from '@/residency/utils/notification-util'
import notificationTypes from '@/residency/views/schedule/notification/notification-types'
import log from '@/residency/utils/log'

const getDefaultState = () => {
  return {
    notifications: {},
    asmtIdToNotifId: {},
    hasNewAsmtNotif: false,
    hasNewNotifCards: false
  }
}
const state = getDefaultState()

const getters = {
  notifications: state => {
    return state.notifications
  },
  asmtIdToNotifId: state => {
    return state.asmtIdToNotifId
  },
  isNewAsmt: state => asmtId => {
    return state.asmtIdToNotifId.hasOwnProperty(asmtId)
  },
  hasNewAsmtNotif: state => {
    return state.hasNewAsmtNotif
  },
  hasNewNotifCards: state => {
    return state.hasNewNotifCards
  }
}

const actions = {
  fetchNotifications: async ({ commit, rootGetters }) => {
    if (!rootGetters.me.isActive || rootGetters.isAdministrativeRole) return
    try {
      const resp = await simseiApi.get('/notification/search/findAllByUserIdAndProgramId', {
        params: {
          userId: rootGetters.me.id,
          programId: rootGetters.programId
        }
      })
      Object.entries(resp.data._embedded).forEach(([notifType, notifArray]) => {
        notifArray.forEach(notif => {
          switch (notif.type) {
            case notificationTypes.NEW_VIDEO_ASMT:
            case notificationTypes.NEW_ASMT:
              commit('ADD_ASMT_TO_NOTIF_MAP', notif)
              break
            case notificationTypes.EVENT:
              parseEventDeletedNotification(notif)
              break
            case notificationTypes.SHARED_LIBRARY:
              break
            case notificationTypes.REPLY:
              commit('ADD_REPLY_NOTIF_MAP', notif)
              break
            default:
              log.error('Notification has an unrecognized type; ', notif)
          }
          commit('ADD_NOTIFICATION', notif)
        })
      })
    } catch (e) {
      log.error(e)
    }
  },
  addNewWSNotif: ({ commit }, notif) => {
    switch (notif.type) {
      case notificationTypes.NEW_VIDEO_ASMT:
      case notificationTypes.NEW_ASMT:
        commit('ADD_ASMT_TO_NOTIF_MAP', notif)
        commit('SET_HAS_NEW_ASMT', true)
        break
      case notificationTypes.EVENT:
        parseEventDeletedNotification(notif)
        break
      case notificationTypes.SHARED_LIBRARY:
        break
      case notificationTypes.REPLY:
        commit('ADD_REPLY_NOTIF_MAP', notif)
        break
      default:
        log.error('Notification has an unrecognized type; ', notif)
    }
    commit('ADD_NOTIFICATION', notif)
    if (isCardNotification(notif.type)) {
      commit('SET_HAS_NEW_NOTIF_CARDS', true)
    }
  },
  deleteNotification: async ({ commit, state }, notif) => {
    const notifId = notif.id
    try {
      await simseiApi.delete(`/notification/${notifId}`)
    } catch (e) {
      log.error(e)
    }

    switch (notif.type) {
      case notificationTypes.NEW_ASMT:
        commit('DELETE_ASMT_TO_NOTIF_MAP', notif.asmtId)
        break
      case notificationTypes.SHARED_LIBRARY:
        break
      case notificationTypes.REPLY:
        commit('DELETE_REPLY_NOTIF_MAP', notif.asmtId)
        break
      case notificationTypes.NEW_VIDEO_ASMT:
        commit('DELETE_ASMT_TO_NOTIF_MAP', notif.asmtId)
        break
      case notificationTypes.EVENT:
        // Event notifications aren't stored in a separate map
        // so we don't need to do anything for these notifications
        break
      default:
        log.error('Unrecognized Notification Type', notif.type)
    }
    commit('DELETE_NOTIFICATION', notifId)
  },
  clearNotificationStore: ({ commit }) => {
    commit('RESET_NOTIFICATION_STORE')
  }
}

const mutations = {
  DELETE_NOTIFICATION (state, notifId) {
    delete state.notifications[notifId]
  },
  ADD_NOTIFICATION (state, newNotif) {
    state.notifications[newNotif.id] = newNotif
  },
  SET_HAS_NEW_ASMT (state, newBool) {
    state.hasNewAsmtNotif = newBool
  },
  SET_HAS_NEW_NOTIF_CARDS (state, newBool) {
    state.hasNewNotifCards = newBool
  },
  ADD_ASMT_TO_NOTIF_MAP (state, notif) {
    state.asmtIdToNotifId[notif.asmtId] = notif.id
  },
  DELETE_ASMT_TO_NOTIF_MAP (state, asmtId) {
    delete state.asmtIdToNotifId[asmtId]
  },
  ADD_REPLY_NOTIF_MAP (state, notif) {
    state.asmtIdToNotifId[notif.asmtId] = notif.id
  },
  DELETE_REPLY_NOTIF_MAP (state, asmtId) {
    delete state.asmtIdToNotifId[asmtId]
  },
  RESET_NOTIFICATION_STORE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
