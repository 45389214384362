import { cancelUploadTaskRequestStatus } from '@/residency/views/video/video-enums'
import log from '@/residency/utils/log'
import { simseiApi } from '@/residency/app-props'

const getDefaultState = () => {
  return {
    // key: uploadId, value: { totalSize, uploadedSize, secondsRemaining, isPaused, cancelRequest, uploadTask }
    videoUploadProgressInfo: new Map(),
    pausedUploadTasks: new Map() // Map<uploadId, Object (info)>
  }
}

const state = getDefaultState()

const getters = {
  videoUploadProgressInfo (state) {
    return state.videoUploadProgressInfo
  },
  pausedUploadTasks (state) {
    return Array.from(state.pausedUploadTasks.values())
  },
  pausedUploadTasksMap (state) {
    return state.pausedUploadTasks
  }
}

const actions = {
  startResumeUpload: async ({ commit, dispatch }, { file, uploadId, onStarted }) => {
    await simseiApi.patch(`tus/upload/resume?taskId=${uploadId}`)
    const creationResponse = dispatch('uploadWithTus', {
      file,
      uploadUrl: `/api/v1/tus/upload/${uploadId}`,
      onUploadTaskCreated: (upload) => {
        onStarted()
        commit('INITIALIZE_UPLOAD_PROGRESS', { uploadId, uploadTask: upload })
      }
    })
    const uploadInfo = (await creationResponse).data
    return uploadInfo
  },
  pauseUploadTask: async ({ commit, getters }, uploadId) => {
    if (!getters.videoUploadProgressInfo.has(uploadId)) {
      log.warn('Cannot pause upload task because it does not exist')
      return false
    } else {
      try {
        const upload = getters.videoUploadProgressInfo.get(uploadId).uploadTask
        upload.abort()
        await simseiApi.patch(`tus/upload/pause?taskId=${uploadId}`)
      } catch (e) {
        log.error('Failed to pause upload task', e)
        return false
      }

      commit('UPDATE_UPLOAD_PROGRESS', {
        uploadId,
        totalSize: getters.videoUploadProgressInfo.get(uploadId).totalSize,
        uploadedSize: getters.videoUploadProgressInfo.get(uploadId).uploadedSize,
        secondsRemaining: getters.videoUploadProgressInfo.get(uploadId).secondsRemaining,
        isPaused: true,
        cancelRequest: getters.videoUploadProgressInfo.get(uploadId).cancelRequest,
        uploadTask: getters.videoUploadProgressInfo.get(uploadId).uploadTask
      })

      return true
    }
  },
  createCancelUploadTaskRequest: ({ commit, getters }, uploadId) => {
    if (!getters.videoUploadProgressInfo.has(uploadId)) {
      log.warn('Cannot cancel upload task because it does not exist')
      return false
    } else {
      commit('UPDATE_UPLOAD_PROGRESS', {
        uploadId,
        totalSize: getters.videoUploadProgressInfo.get(uploadId).totalSize,
        uploadedSize: getters.videoUploadProgressInfo.get(uploadId).uploadedSize,
        secondsRemaining: getters.videoUploadProgressInfo.get(uploadId).secondsRemaining,
        isPaused: getters.videoUploadProgressInfo.get(uploadId).isPaused,
        cancelRequest: cancelUploadTaskRequestStatus.PENDING,
        uploadTask: getters.videoUploadProgressInfo.get(uploadId).uploadTask
      })

      const upload = getters.videoUploadProgressInfo.get(uploadId).uploadTask
      upload.abort()
      return true
    }
  },
  denyCancelUploadTaskRequest: ({ commit, getters }, uploadId) => {
    if (!getters.videoUploadProgressInfo.has(uploadId)) {
      log.warn('Cannot deny cancel upload task request because it does not exist')
      return false
    } else {
      commit('UPDATE_UPLOAD_PROGRESS', {
        uploadId,
        totalSize: getters.videoUploadProgressInfo.get(uploadId).totalSize,
        uploadedSize: getters.videoUploadProgressInfo.get(uploadId).uploadedSize,
        secondsRemaining: getters.videoUploadProgressInfo.get(uploadId).secondsRemaining,
        isPaused: getters.videoUploadProgressInfo.get(uploadId).isPaused,
        cancelRequest: null,
        uploadTask: getters.videoUploadProgressInfo.get(uploadId).uploadTask
      })

      const upload = getters.videoUploadProgressInfo.get(uploadId).uploadTask
      upload.start()
      return true
    }
  },
  cancelUploadTask: ({ commit, dispatch }, uploadId) => {
    commit('REMOVE_UPLOAD_PROGRESS', uploadId)
    dispatch('terminatePausedUploadTask', uploadId)
  },
  fetchPausedUploadTasks: async ({ commit }) => {
    const res = await simseiApi.get('tus/upload/my-paused-tasks')
    commit('SET_PAUSED_UPLOAD_TASKS', res.data)
  },
  terminatePausedUploadTask: async ({ dispatch }, uploadId) => {
    await simseiApi.patch(`tus/upload/cancel?taskId=${uploadId}`)
    dispatch('fetchPausedUploadTasks')
  }
}

const mutations = {
  INITIALIZE_UPLOAD_PROGRESS (state, { uploadId, uploadTask }) {
    state.videoUploadProgressInfo.set(uploadId, {
      totalSize: -1,
      uploadedSize: -1,
      secondsRemaining: -1,
      isPaused: false,
      cancelRequest: null,
      uploadTask
    })
  },
  UPDATE_UPLOAD_PROGRESS (
    state,
    { uploadId, totalSize, uploadedSize, secondsRemaining, isPaused, cancelRequest, uploadTask }
  ) {
    // trigger a reactivity update
    const mapCopy = new Map(state.videoUploadProgressInfo)
    mapCopy.set(uploadId, {
      totalSize,
      uploadedSize,
      secondsRemaining,
      isPaused,
      cancelRequest,
      uploadTask
    })
    state.videoUploadProgressInfo = mapCopy
  },
  REMOVE_UPLOAD_PROGRESS (state, uploadId) {
    state.videoUploadProgressInfo.delete(uploadId)
  },
  SET_PAUSED_UPLOAD_TASKS (state, pausedUploadTasks) {
    // build map from array
    const tasks = new Map()
    for (const task of pausedUploadTasks) {
      tasks.set(task.id, task)
    }

    state.pausedUploadTasks = tasks
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
