import { simseiApi } from '@/residency/app-props'
import { randomString } from '@/utils/string-util.js'

const getDefaultState = () => {
  return {
    evaluationForm: {
      rhdQuestions: []
    },
    isValidRhdAsmt: true
  }
}

const state = getDefaultState()

const getters = {
  evaluationForm: state => state.evaluationForm,
  isValidRhdAsmt: state => state.isValidRhdAsmt
}

const actions = {
  fetchRhdEvaluationForm: async ({ commit }, formId) => {
    const response = await simseiApi.get(`/simulation-grs/${formId}`, {
      params: {
        projection: 'rhdEvaluationQuestions'
      }
    })

    const formData = response.data
    return {
      id: formData.id,
      type: formData.type,
      questions: formData.rhdQuestions.map(parseRhdQuestion).sort((a, b) => a.order - b.order)
    }
  },
  validateRhdForm: async ({ commit, dispatch }) => {
    let isRhdFormValid = true
    for (const question of state.evaluationForm.rhdQuestions) {
      if (!question.options.length) {
        isRhdFormValid = false
        commit('SET_VALID_RHD_ASMT', false)
      }
    }
    const isGrsFormValid = await dispatch('isGrsAsmtValid')

    return isRhdFormValid && isGrsFormValid
  },
  submitRhdForm: ({ rootGetters }, residentId) => {
    const asmt = JSON.parse(JSON.stringify(rootGetters.grsAsmt))
    asmt.assessedUser.id = residentId
    asmt.comments = rootGetters.videoFeedbackComments
    asmt.isOfficialReview = rootGetters.isInstructor
    asmt.evaluationForm = rootGetters.evaluationForm
    asmt.isRequestedForSharedLibrary = false

    const response = simseiApi.post('/simulation/assessment/rhd', asmt)
    return response
  }
}

const mutations = {
  PREPARE_RHD_FORM (state, rhdForm) {
    const evaluationForm = state.evaluationForm
    evaluationForm.id = rhdForm.id
    evaluationForm.type = rhdForm.type
    evaluationForm.rhdQuestions = []

    for (const question of rhdForm.questions) {
      evaluationForm.rhdQuestions.push({
        id: question.questionId,
        options: []
      })
    }
  },
  SET_EVALUATION_QUESTION_OPTION (state, { index, optionId }) {
    const question = state.evaluationForm.rhdQuestions[index]
    question.options = [{ id: optionId }]
  },
  SET_VALID_RHD_ASMT (state, valid) {
    state.isValidRhdAsmt = valid
  },
  RESET_RHD_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

function parseRhdQuestion (question) {
  return {
    questionId: question.id,
    title: question.question,
    order: question.order,
    key: randomString(),
    options: question.options.map(option => ({
      optionId: option.id,
      value: option.value,
      subText: option.subText,
      description: option.text,
      order: option.optionOrder,
      key: randomString()
    })).sort((a, b) => a.order - b.order)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
