const getDefaultState = () => {
  return {
    printButton: {
      isEnabled: false
    }
  }
}
const state = getDefaultState()

const actions = {
  showPrintButton ({ commit }) {
    commit('SET_PRINT_BUTTON', { enable: true })
  },
  hidePrintButton ({ commit }) {
    commit('SET_PRINT_BUTTON', { enable: false })
  }
}

const getters = {
  printButton (state) {
    return state.printButton.isEnabled
  }
}

const mutations = {
  SET_PRINT_BUTTON (state, payload) {
    state.printButton.isEnabled = payload.enable
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
