import { simseiApi } from '@/residency/app-props'

const getDefaultState = () => {
  return {
    programId: null,
    programs: [],
    selectedProgramId: null,
    programSurgicalProcedures: [],
    specialties: []
  }
}

const state = getDefaultState()

const getters = {
  programs: state => {
    return state.programs
  },
  selectedProgramId: state => {
    return state.selectedProgramId
  },
  selectedProgram: state => {
    return state.programs.find(program => program.id === state.selectedProgramId)
  },
  programId: state => {
    return state.programId
  },
  specialties: state => {
    return state.specialties
  }
}

const actions = {
  setProgramId: ({ commit }, programId) => {
    commit('SET_PROGRAM_ID', programId)
  },
  getSpecialities: async ({ commit }) => {
    const response = await simseiApi.get('/specialty')
    const specialties = response.data._embedded.specialty
    commit('SET_SPECIALTIES', specialties)
    return specialties
  },
  getTimezones: async ({ commit }) => {
    const response = await simseiApi.get('/timezone')
    return response.data._embedded.timezone_option
  },
  getProgramSurgicalProcedures: async ({ commit, state }) => {
    if (state.programSurgicalProcedures.length) return state.programSurgicalProcedures

    const response = await simseiApi.get('/video/surgical/procedures')
    const procedures = response.data
    commit('SET_PROGRAM_SURGICAL_PROCEDURES', procedures)

    return procedures
  },
  clearProgramState: ({ commit }) => {
    commit('RESET_PROGRAMS')
  }
}

const mutations = {
  SET_PROGRAM_ID (state, programId) {
    state.programId = programId
  },
  SET_PROGRAM_SURGICAL_PROCEDURES (state, procedures) {
    state.programSurgicalProcedures = procedures
  },
  RESET_PROGRAMS (state) {
    Object.assign(state, getDefaultState())
  },
  SET_SPECIALTIES (state, specialties) {
    state.specialties = specialties
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
