import { makeLrsAgentForUser } from '@/residency/utils/lrs-util'
import { simseiApi } from '@/residency/app-props'
import viewModes from '@/residency/consts/resident-view-modes'
import userRoles from '@/residency/consts/user-roles'
import userGroupAllOption from '@/residency/consts/user-group-all-option.js'
import WebSocketManager from '@/residency/websocket'

const tokenInfo = () => ({
  accessToken: '',
  refreshToken: '',
  accessTokenDuration: 0,
  accessTokenRetrievedDate: new Date()
})

const me = () => ({
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  role: '',
  isActive: false,
  profileInfo: {},
  timezone: ''
})

const defaultState = () => ({
  tokenInfo: tokenInfo(),
  me: me()
})

const state = defaultState()

const getters = {
  isAuthenticated: state => !!state.tokenInfo.refreshToken,
  getTokenInfo: state => state.tokenInfo,
  getUserRole: state => state.me.role || '',
  me: state => state.me,
  lrsAgent: state => {
    if (!state.me.email) { return }
    return makeLrsAgentForUser(state.me.email, state.me.fullName)
  },
  getTimezone: state => state.me.timezone
}

const actions = {
  login: async ({ commit, dispatch, rootGetters }, authToken) => {
    commit('SAVE_NEW_AUTH_TOKEN', authToken)
    await dispatch('requestMe')
    await dispatch('updateAvailableCourses')
    if (rootGetters.isAdministrativeRole) {
      await dispatch('getSystemsData')
    }
  },
  requestMe: ({ dispatch, commit, rootGetters }) => {
    return simseiApi.get('/me').then(async resp => {
      commit('SET_ME', resp.data)
      dispatch('setUserGroups', resp.data.userGroups)
      dispatch('setProctorGroups', resp.data.proctorGroups)
      switch (resp.data.role) {
        case (userRoles.RESIDENT):
          if (resp.data.isActive) {
            dispatch('setCurrentUserGroupId', resp.data.currentUserGroupId)
            dispatch('setSelectedUserGroup', resp.data.currentUserGroupId)
            commit('SET_ASMT_HISTORY_USER_GROUP_ID', resp.data.currentUserGroupId)
          } else {
            if (resp.data.userGroups[0]) {
              // inactive residents may not have a currentUserGroup so select a previous one
              // This is so they can login with curricular history
              dispatch('setSelectedUserGroup', resp.data.userGroups[0].id)
              commit('SET_ASMT_HISTORY_USER_GROUP_ID', resp.data.userGroups[0].id)
            }

            // If the resident is inactive but is proctoring active groups,
            // put them into proctor mode for that group. If not,
            // then it will fall back into curricular history mode
            const activeProctorGroup = resp.data.proctorGroups.find(proctorGroup => proctorGroup.isActive)
            if (activeProctorGroup) {
              dispatch('changeViewType', viewModes.PROCTOR)
              dispatch('setSelectedUserGroup', activeProctorGroup.id)
              commit('SET_ASMT_HISTORY_USER_GROUP_ID', activeProctorGroup.id)
              commit('SET_VIDEO_QUEUE_USER_GROUP_ID', activeProctorGroup.id)
            }
          }
          dispatch('setProgramId', resp.data.programId)
          break
        case (userRoles.FACULTY):
        case (userRoles.PROG_DIRECTOR):
          dispatch('setProgramId', resp.data.programId)
          // Set "all" option as the selected user group
          await dispatch('setSelectedUserGroup', userGroupAllOption.id)

          break
        default:
          // do nothing
      }
      return resp.data
    }).catch(error => {
      throw error
    })
  },
  clearState: ({ commit, dispatch }) => {
    commit('CLEAR_AUTH_STATE')
    commit('CLEAR_ME')
    WebSocketManager.getInstance().disconnect()
    dispatch('clearLayout')
    dispatch('clearNotificationStore')
    dispatch('clearToastNotificationStore')
    dispatch('clearCourses')
    dispatch('clearEvents')
    dispatch('clearVideoRecorder')
    dispatch('resetVideoAsmtState')
    dispatch('clearVideoDeviceDetailsState')
    dispatch('clearProctorState')
    dispatch('clearAdminState')
    dispatch('clearUserState')
    dispatch('clearBannerState')
    dispatch('clearUserGroups')
    dispatch('clearDashboardProgressData')
    dispatch('resetVideoFeedbackState')
    dispatch('resetSubmissionState')
    dispatch('clearSharedVideoState')
    dispatch('clearProgramState')
    commit('CLEAR_AR_GAME_STATE')
  }
}

const mutations = {
  SAVE_NEW_AUTH_TOKEN: (state, authToken) => {
    /* eslint-disable camelcase */
    const { access_token, refresh_token, expires_in } = authToken

    const newTokenInfo = {
      accessToken: access_token,
      refreshToken: refresh_token,
      accessTokenDuration: expires_in,
      accessTokenRetrievedDate: new Date()
    }
    /* eslint-enable camelcase */

    state.tokenInfo = newTokenInfo
  },
  SET_ME: (state, meDto) => {
    state.me.id = meDto.id
    state.me.email = meDto.email
    state.me.role = meDto.role
    state.me.firstName = meDto.firstName
    state.me.lastName = meDto.lastName
    state.me.fullName = meDto.firstName + ' ' + meDto.lastName
    state.me.profileInfo = meDto.profileInfo
    state.me.timezone = meDto.timezone
    state.me.isActive = meDto.isActive
  },
  SET_PROFILE_INFO: (state, profileInfo) => {
    state.me.profileInfo = profileInfo
  },
  CLEAR_AUTH_STATE: (state) => {
    Object.assign(state.tokenInfo, tokenInfo())
  },
  CLEAR_ME: (state) => {
    Object.assign(state.me, me())
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
