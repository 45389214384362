import { videoStreamTypes } from '@/residency/views/video/video-enums'

const getDefaultState = () => {
  return {
    srcObject: {
      [videoStreamTypes.LAPAROSCOPE]: null,
      [videoStreamTypes.FACECAM]: null
    },
    preselectedLaparoscopeDeviceId: '',
    preselectedFacecamDeviceId: ''
  }
}

const state = getDefaultState()

const getters = {
  preselectedLaparoscopeDeviceId (state) {
    return state.preselectedLaparoscopeDeviceId
  },
  preselectedFacecamDeviceId (state) {
    return state.preselectedFacecamDeviceId
  },
  srcObjectLaparoscope (state) {
    return state.srcObject[videoStreamTypes.LAPAROSCOPE]
  },
  srcObjectFacecam (state) {
    return state.srcObject[videoStreamTypes.FACECAM]
  }
}

const actions = {
  setPreselectedDeviceId: ({ commit }, { deviceId, deviceType }) => {
    if (deviceType === videoStreamTypes.LAPAROSCOPE) {
      commit('SET_PRESELECTED_LAPAROSCOPE_DEVICE_ID', deviceId)
    } else if (deviceType === videoStreamTypes.FACECAM) {
      commit('SET_PRESELECTED_FACECAM_DEVICE_ID', deviceId)
    }
  },
  resetPreselectedDeviceId: ({ commit }, deviceType) => {
    if (deviceType === videoStreamTypes.LAPAROSCOPE) {
      commit('RESET_PRESELECTED_LAPAROSCOPE_DEVICE_ID')
    } else if (deviceType === videoStreamTypes.FACECAM) {
      commit('RESET_PRESELECTED_FACECAM_DEVICE_ID')
    }
  },
  clearVideoDeviceDetailsState: ({ commit }) => {
    commit('RESET_DEVICE_DETAILS_STATE')
  }
}

const mutations = {
  SET_PRESELECTED_LAPAROSCOPE_DEVICE_ID (state, deviceId) {
    state.preselectedLaparoscopeDeviceId = deviceId
  },
  SET_PRESELECTED_FACECAM_DEVICE_ID (state, deviceId) {
    state.preselectedFacecamDeviceId = deviceId
  },
  SET_SRC_OBJECT_FOR_STREAM (state, { streamType, srcObject }) {
    const newSrcObject = state.srcObject
    newSrcObject[streamType] = srcObject
    state.srcObject = newSrcObject
  },
  RESET_PRESELECTED_LAPAROSCOPE_DEVICE_ID (state) {
    state.preselectedLaparoscopeDeviceId = ''
  },
  RESET_PRESELECTED_FACECAM_DEVICE_ID (state) {
    state.preselectedFacecamDeviceId = ''
  },
  RESET_DEVICE_DETAILS_STATE (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
