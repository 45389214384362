<template>
  <!-- Manages displaying toast notifications. See enqueueToastNotification in notification.js for more info-->
  <ToastNotification v-if="showToast" :toastType="type" :timeout="timeout" data-cy="toast-notification" @close="closeToast">
    <div v-if="shouldRenderUnsafely()">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="message" id="custom-toast-msg"></span>
    </div>
    <div v-else>
      <span id="strict-toast-msg" data-cy="toast-msg">{{ message }}</span>
    </div>
  </ToastNotification>
</template>

<script>
import ToastNotification from '@/components/toast/ToastNotification'
import toastTypes from '@/components/toast/toast-types'

export default {
  components: { ToastNotification },
  data () {
    return {
      showToast: false,
      message: '', // message to display in toast notification
      timeout: 3000,
      useUnsafeHTML: false, // see notification.js for more info
      type: toastTypes.ERROR,
      onClose: null, // function to call when toast is closed
      onOpen: null // function to call when toast is opened
    }
  },
  watch: {
    '$store.getters.hasToastNotification' (to, from) {
      if (to && !this.showToast) {
        this.showNextToastMessage()
      }
    }
  },
  created () {
    if (this.$store.getters.hasToastNotification) {
      this.showNextToastMessage()
    }
  },
  methods: {
    async closeToast () {
      this.showToast = false
      if (this.onClose) {
        this.onClose()
      }
      await this.$store.dispatch('popFrontToastNotification')
      if (this.$store.getters.hasToastNotification) {
        setTimeout(() => {
          this.showNextToastMessage()
        }, 1500)
      }
    },
    async showNextToastMessage () {
      const { msg, timeout, type, onClose, onOpen, useUnsafeHTML } = await this.$store.dispatch('fetchNextToastNotification')
      if (onOpen) {
        onOpen()
      }

      this.message = msg
      this.timeout = timeout
      this.type = type
      this.showToast = true
      this.onClose = onClose
      this.useUnsafeHTML = !!useUnsafeHTML
    },
    shouldRenderUnsafely () {
      return this.useUnsafeHTML
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
